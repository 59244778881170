import React, {Component} from 'react';
import axios from "axios/index";
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import {Link} from "react-router-dom";
import _ from "underscore";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import moment from "moment/moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import {
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  ButtonGroup, Button, Col, Row,
  InputGroup,
  InputGroupAddon,
  ListGroup, ListGroupItem,
  Popover, PopoverBody, PopoverHeader
} from "shards-react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.scss'
import { MileageCalculator } from "../../../utils/mileageCalculator.js";
import ModalLoading from "../../../components/common/ModalLoading";
import {Common as util} from "../../../utils/common";
import DateTimePicker from "react-datetime-picker";


function isOverThreeMonths(date1, date2) {
  const startDate = moment(date1);
  const endDate = moment(date2);
  const monthsDiff = endDate.diff(startDate, 'months');
  return monthsDiff < 3;
}

@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ common }) => ({ getDoc: common.getDoc, getStoreTotalConfig: common.getStoreTotalConfig }))
@inject(({ review }) => ({ getReviewList:review.getReviewList, setReviewSync: review.setReviewSync,
  getReviewHistoryList:review.getReviewHistoryList, getReviewMileageInfo:review.getReviewMileageInfo, updateMileage: review.updateMileage, updatePayMileage: review.updatePayMileage,
  updateReviewDisplayStatus: review.updateReviewDisplayStatus, updateReviewSome: review.updateReviewSome, updateReviewComment: review.updateReviewComment, genAIComment: review.genAIComment, commentSmartCorrent: review.commentSmartCorrent, adminDelReview:review.adminDelReview }))
@inject(({ widget }) => ({ getWidgetList:widget.getWidgetList, getWidgetInReviewList:widget.getWidgetInReviewList, updateWidgetInReview: widget.updateWidgetInReview, updateWidgetInReviewOrder: widget.updateWidgetInReviewOrder, removeWidgetInReview: widget.removeWidgetInReview }))

@observer
class ReviewMileage extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  //page
  @observable initialGetReviewList = true;
  @observable isShowDetailSearch = true;

  @observable searchFormValue = {
    searchType: 'all',
    keyword: '',
    startDate: moment().add(-7, 'days').format('yyyy-MM-DD'),
    endDate: moment().format('yyyy-MM-DD'),
    star: {
      all: true,
      star5: true,
      star4: true,
      star3: true,
      star2: true,
      star1: true,
    },
    writer: {
      all: true,
      member: true,
      admin: true,
    },
    reviewType: {
      all: true,
      text: true,
      image: true,
      // movie: true,
      // survey: true,
    },
    reviewDis: {
      all: true,
      on: true,
      will: true,
      pending: true,
    },
    isDelete: false,
    noComment: false
  };
  @observable widgets = {};
  @observable syncReviewValue = {
    startDate: moment().add(-1, 'days').format('yyyy-MM-DD'),
    endDate: moment().format('yyyy-MM-DD'),
    isOverwrite: false,
  };
  @observable basicSettings = {};


  @observable isShowCalendar = {
    startDate: false,
    endDate: false
  }

  @observable isShowCalendarForRS = {
    startDate: false,
    endDate: false
  }

  //popover
  @observable smartCommentPopover = {};

  //tooltip
  @observable isReviewHistoryTip = false;
  @observable ismileageHistoryTip = false;

  @observable listParams = {
    page:1, limit:10,
    displayStatus: 100,
    searchParams: {
      displayAt: 'all'
    },
    isPopulateProductReply: true
  }
  @observable list = {};
  @observable isReplyWrite = false;

  @observable curReview = {
    mileageInfo : {}
  };
  @observable addMileage = {
    type: 'increase',
    reason: '',
    pointAmout: '',
  };
  @observable reviewMileageConfig;
  @observable reviewHistoryListParams = {page:1, limit:5};
  @observable reviewMileageInfParams = {}
  @observable curReviewHistoryList = [];
  @observable curReviewMileageInfo = {};

  @observable modals = {
    isSyncReview: false,
    isMileage: false
  };

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeSearchValue = this.onChangeSearchValue.bind(this);
    this.onChangeCalendarValue = this.onChangeCalendarValue.bind(this);
    //this.removeWidgetInReview = this.removeWidgetInReview.bind(this);
    this.onChangeSyncReviewValue = this.onChangeSyncReviewValue.bind(this);
  }

  @action
  init = async (key) => {

    let _this = this;
    if(this.props.isLogin) {
      this.initialGetReviewList = Storage.get('initialGetReviewList') ? Storage.get('initialGetReviewList') : false;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.listParams.shopNo = this.teamUser.selShopNo ? this.teamUser.selShopNo : 1;
      this.isUse = false;
      if(this.teamUser.isPaidShop) {
        this.isUse = true;
      }
      if(!this.props.me || !this.props.me.basicSettings) {
        console.log('null me');
      }
      else {

        let res = await this.props.getStoreTotalConfig({store_id: this.teamUser.selStore_id});
        this.props.me.basicSettings = res.basicSettings
        this.reviewMileageConfig = res.mileageConfig;
        console.log(this.reviewMileageConfig)

        // this.props.getDoc('getMileageConfig', { store_id:this.teamUser.selStore_id }, function(res) {
        //   //todo 마일리지 설정이 안되어 있을 경우 경고창과 함께 설정 페이지로 이동
        //   if(res.data._id) {
        //     _this.reviewpointConfig = res.data;
        //   }
        //   else {
        //     alert('마일리지 지급 설정 후 사용이 가능합니다.');
        //     //_this.props.history.push('/reviewMileageConfig');
        //   }
        // });


        if(this.props.me.basicSettings && this.props.me.basicSettings.reviewRatingConfigs) {
          this.basicSettings = this.props.me.basicSettings;
          this.basicSettings.reviewRatingConfigs = _.indexBy(this.props.me.basicSettings.reviewRatingConfigs, 'rating');
        }
        else {
          let isRedirect = window.confirm("리뷰별점 답글마법사 설정이 되어 있지 않습니다.설정 페이지로 이동하시겠습니까?");
          if(isRedirect) {
            window.location.href = '/reviewWriteConfig#reviewRatingConfigs';
          }
        }
      }

      this.store.replyCount = this.props.me.replyCount ? this.props.me.replyCount : {};
      if(this.store.replyCount.writeCount >= 10) {
        alert("무료 답글 작성 기회가 모두 소진되었습니다. 결제 후 더 자유롭게 이용해 보세요.");
        this.isReplyWrite = false;
      }
      else {
        this.isReplyWrite = true;
      }

      this.permissions = this.props.me.permissions;

      this.listParams.store_id = this.teamUser.selStore_id;
      this.urlParamsToListParams();
    }
  };

  urlParamsToListParams = function() {

    let searchFormValueArr = {};
    for (const key of this.urlParams.keys()) {

      if(['page'].indexOf(key) > -1 || ['limit'].indexOf(key) > -1) {
        this.listParams[key] = parseInt(this.urlParams.get(key));
      }
      else {
        if(key.indexOf('__') > -1) {
          let keyArr = key.split('__');
          if(!searchFormValueArr[keyArr[0]]) { searchFormValueArr[keyArr[0]] = {};}
          searchFormValueArr[keyArr[0]][keyArr[1]] = true;
        }
        else {
          this.searchFormValue[key] = this.urlParams.get(key);
          this.listParams.searchParams[key] = this.urlParams.get(key);
        }
      }
    }

    for(let key in searchFormValueArr) {
      if(searchFormValueArr[key].all) {
        for(let deKey in this.searchFormValue[key]) {
          this.searchFormValue[key][deKey] = true;
        }
      }
      else {
        for(let deKey in this.searchFormValue[key]) {
          if(searchFormValueArr[key][deKey]) {
            this.searchFormValue[key][deKey] = true;
          }
          else {
            this.searchFormValue[key][deKey] = false;
          }
        }
      }
      this.listParams.searchParams[key] = searchFormValueArr[key];
    }

    this.getWidgetList();
    this.getReviewList();
  }


  //ui fn
  doSearch = async (isReset) => {

    if (isReset) {
      window.location = window.location.pathname;
    } else {
      this.searchFormValue.searchType = 'search';
      let urlParams = new URLSearchParams();
      if (this.searchFormValue['widget_id']) {
        urlParams.set('widget_id', this.searchFormValue['widget_id']);
      } else {
        for (let key in this.searchFormValue) {
          if (key != 'store_id' && key != 'teamUserId' && key != 'limit' && this.searchFormValue[key]) {
            if (typeof this.searchFormValue[key] == 'object') {
              if (!this.searchFormValue[key]['all']) {
                for (let depthKey in this.searchFormValue[key]) {
                  if (this.searchFormValue[key][depthKey]) {
                    urlParams.set(key + '__' + depthKey, this.searchFormValue[key][depthKey]);
                  }
                }
              } else {
                urlParams.set(key + '__all', this.searchFormValue[key]['all']);
              }

            } else {
              urlParams.set(key, this.searchFormValue[key]);
            }
          }
        }
      }
      window.location.search = urlParams.toString();
    }

  };

  onChangeSearchValue = async (event, obj) => {
    const { name, value, type } = event.target;
    let newValue;
    if(type == 'checkbox') {
      if(value) {
        newValue = !JSON.parse(value);
      }
      else {
        newValue = true;
      }
    }
    else {
      newValue = value;
    }

    if(obj) {
      console.log(obj, newValue)
      obj[name] = newValue;

      if(name == 'all') {
        for(let key in obj) {
          obj[key] = newValue;
        }
      }
      else {
        let isAll = false;
        if(newValue) {
          isAll = true;
          for(let key in obj) {
            if(!obj[key] && key != 'all') {
              isAll = false;
              break;
            }
          }
        }
        obj['all'] = isAll;
      }
    }
    else {
      this.searchFormValue[name] = newValue;
      if(name == 'isDelete') {
        for(let key in this.searchFormValue.reviewDis) {
          this.searchFormValue.reviewDis[key] = !newValue;
        }
      }
    }

  };

  onChangeCalendarValue = async (date, dateType) => {
    this.isShowCalendar = {
      startDate: false,
      endDate: false,
    };
    this.searchFormValue[dateType] = moment(date).format('yyyy-MM-DD');
  };

  onChangeValue =  async (event, targetObj) => {
    const { name, value } = event.target;
    console.log(targetObj)
    targetObj[name] = value;
  };

  // onChangeValueAndUpdate = async (type, event, item) => {
  //
  //   const { name, value } = event.target;
  //   if(!value || value == '') {
  //     return false;
  //   }
  //   let params = {};
  //
  //   let result;
  //   params = {
  //     id: item._id,
  //     preStatus: item.displayStatus
  //   };
  //   params[name] = parseInt(value);
  //   result = await this.props.updateReviewDisplayStatus(params);
  //   toast('게시 상태가 변경되었습니다.');
  //   item[name] = params[name];
  //
  // };


  onChangeValueAndUpdate = async (type, event, item) => {

    const { name, value } = event.target;
    if(!value || value == '') {
      return false;
    }
    let params = {};

    let result;
    if(type === 'widget_id') {
      let updatewidgetId = value;
      params = {
        review_id: item._id,
        widget_id: value,
      };

      if(item.widget_ids && item.widget_ids.indexOf(params.widget_id) > -1) {
        // todo 이미 해당 리뷰의 위젯이 추가된 상황
        toast('이미 위젯이 추가 되었있습니다.');
        // params.reviewWidgetRef_ids = item.reviewWidgetRef_ids;
        // //todo reviewWidgetRef 해당 값 가져오기
        // params.review_ids = [item._id]
      }
      else {
        this.props.updateWidgetInReview(params, function(res) {
          if(!item.widget_ids) {
            item.widget_ids = [];
          }
          item.widget_ids.push(params.widget_id);
        });
      }
    }
    else if(type == 'payMileageStatus') {
      if(item.userOrder_id && item.userOrder_id._id) {
        params = {
          review_id: item._id,
          userOrder_id: item.userOrder_id._id
        };
        params[name] = parseInt(value);
        result = await this.props.updatePayMileage(params, function(res) {

        });
      }
      else {
        toast('해당 리뷰는 마일리지 처리가 불가능합니다.');
      }

    }
    else {
      params = {
        id: item._id,
        preStatus: item.displayStatus
      };
      params[name] = parseInt(value);
      alert('게시 상태변경에 따른 마일리지 지불 또는 회수 처리는 수동으로 처리 하셔야 합니다.');
      result = await this.props.updateReviewDisplayStatus(params);
      console.log(result)
      toast('게시 상태가 변경되었습니다.');
      item[name] = params[name];
    }
  };

  toggleTip = async (target) => {
    this[target] = !this[target];
  }

  //page fn
  getWidgetList = async() => {
    if(this.listParams.store_id) {
      let params = {page:1, limit:20, store_id:this.listParams.store_id, selecting:'specify'}
      let widgetList = await this.props.getWidgetList(params);
      this.widgets = _.indexBy(widgetList.docs, '_id');
      //console.log(Object.keys(this.widgets).length);
    }
    else {
      //console.log('null store_id');
    }
  };

  getReviewList = async() => {
    if(this.listParams.store_id) {
      let res = await this.props.getReviewList(this.listParams);
      if(!this.searchFormValue.startDate && !this.searchFormValue.endDate && this.initialGetReviewList === false && res.totalDocs === 0) {
        let cf = window.confirm('최신 리뷰 데이터를 가져오시겠습니까?');
        if(cf) {
          Storage.set('initialGetReviewList', true);
          this.setReviewSync('latest');
        }
      }
      _.map(res.docs, function(row) {
        row.product = row.product_id;
        return row;
      });
      this.list = res;
      this.isLoading = false;
    }
    else {
      //console.log('null store_id');
    }
  };

  setReviewSync = async (type) => {
    let _this = this;
    //let confimRes = window.confirm("기존 온리플의 리뷰는 모두 삭제되고 카페24 리뷰로 대체 됩니다. 진행하시겠습니까?");
    _this.isLoading = true;
    let data = {
      store_id: _this.store._id,
      getType: type ? type : 'new',

    }
    if(type == 'period') {
      if(isOverThreeMonths(this.syncReviewValue.startDate, this.syncReviewValue.endDate)) {
        data.params = this.syncReviewValue;
      }
      else {
        alert('최대 3개월 단위로 선택해 주세요.');
        return false;
      }
    }


    _this.props.setReviewSync(data, function(res) {
      if(res && res.data) {
        toast(moment(res.data.startDate).format('YYYY-MM-DD') + ' ~ ' + moment(res.data.endDate).format('YYYY-MM-DD') + ' 기간의 리뷰가 ' +  res.data.insertReviewCount + '개 업데이트 되었습니다.');
        if(res.data.insertReviewCount > 0) {
          _this.getReviewList();
        }
      }
      _this.isLoading = false;
    });

  };

  reviewContentModifyToggle = (item) => {

    if(!item.isEdit) {
      item.orgContents = item.contents;
      item.isEdit = true;
    }
    else {
      item.contents = item.orgContents;
      item.isEdit = false;
    }

  };

  updateReviewContent = async (item) => {
    let result = await this.props.updateReviewComment({id:item._id, comment:{comment: item.comment.content}});
    if(result.e) {
      let cf = window.confirm('답글을 작성하기 위한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
      if(cf) {
        window.location.href = '/reviewWriteConfig';
      }
    }
    item.isEdit = false;
  };

  genAIComment = async (item) => {
    let _this = this;
    let params = {
      store_id: item.store_id,
      review_id: item.id
    }
    if(!item.comment) {
      item.comment = {};
    }
    item.isWriteReady = true;
    let orgContent = item.comment.content;
    item.comment.content = 'AI 답글 생성중.';
    let intervalId = setInterval(() => {
      item.comment.content = item.comment.content + '.';
    }, 200);
    let result = await this.props.genAIComment(params);
    if(result && result.error && result.error.message) {
      item.comment.content = orgContent;
      if(result.error.message === "null storeBasicSettings.") {
       let cf = window.confirm('해당 작업은 답글 작성에 대한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
       if(cf) {
         window.location.href = '/reviewWriteConfig';
       }
      }
      else{
        let cf = window.confirm(result.error.message + '설정 페이지로 이동하시겠습니까?');
        if(cf) {
          window.location.href = '/reviewWriteConfig';
        }
      }
    }
    else {
      if(result.data && result.data.content) {
        item.comment.content = result.data.content;
      }
      else if(result.data && result.data.overWriteCount) {
        alert('무료 답글 작성 기회가 모두 소진되었습니다. 결제 후 더 자유롭게 이용해 보세요.');
        this.store.replyCount.writeCount = 10;
        this.isReplyWrite = false;
      }
    }
    clearInterval(intervalId);
    item.isWriteReady = false;

  };

  genFixComment = async (item, comment) => {
    if(!this.basicSettings.writer) {
        let cf = window.confirm('답글을 작성하기 위한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
        if(cf) {
          window.location.href = '/reviewWriteConfig';
        }
    }
    let updateComment = comment.replace(/\[구매자명\]/g, item.writerName ? util.nameUnexpos(item.writerName) : '고객님')
        .replace(/\[상품명\]/g, item.product && item.product.productName ? item.product.productName : '상품')
        .replace(/\[쇼핑몰명\]/g, this.basicSettings && this.basicSettings.writer ? this.basicSettings.writer : '저희 쇼핑몰')
        .replace(/\[상품별 추가 내용\]\n?/g, item.vr_productReply && item.vr_productReply.tone ? item.vr_productReply.tone : '');
    if(!item.comment) {
      item.comment = {};
    }
    item.comment.content = updateComment;


  }

  commentSmartCorrent = async (item, i) => {
    this.smartCommentPopover[i] = true;
    if(!item.smartComments || !item.smartComments.comment_modify) {
      let params = {
        store_id: item.store_id,
        review_id: item.id,
        comment:item.comment.content
      }
      item.smartComments = {};
      item.smartCommentsReady = '답글을 분석중입니다';
      let intervalId = setInterval(() => {
        item.smartCommentsReady = item.smartCommentsReady + '.';
      }, 200);
      let result = await this.props.commentSmartCorrent(params);
      if(result && result.data) {
        clearInterval(intervalId);
        if(result.data.e) {
          item.smartCommentsReady = "실패하였습니다. 다시 시도하여 주세요."
        }
        else {
          item.smartCommentsReady = '답글 분석 완료';
          item.smartComments = result.data.content;
        }
      }
    }

  };

  updateComment = async (item) => {
    let _this = this;
    console.log(item.comment)
    if(!item.comment || !item.comment.content || item.comment.content == '') {
      alert('답글을 입력해 주세요.');
      return false;
    }
    let result = await this.props.updateReviewComment({id:item._id, comment:{comment: item.comment.content, article_no: item.comment.article_no}});
    if(result && result.data) {
      if(result.data.e) {
        let cf = window.confirm('답글을 작성하기 위한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
        if(cf) {
          window.location.href = '/reviewWriteConfig';
        }
      }
      else {
        toast('답글이 작성되었습니다.');
      }
    }

  };


  delReview = async (item) => {
    let _this = this;
    let message = '리뷰 삭제시 지급한 마일리지는 카페24 관리자에서 처리 하셔야 하며, 삭제 후 복구 불가능합니다. 삭제 진행 하시겠습니까?';
    if(item.isAdmin) {
      message = '리뷰를 정말 삭제 하시겠습니까?'
    }
    let result = window.confirm(message);
    if(result) {
      let params = {
        review_id: item._id,
        store_id:item.store_id
      };
      this.props.adminDelReview(params, function(res) {
        console.log(res);
        toast('리뷰가 삭제되었습니다.');
        _this.getReviewList();
      });
    }

  }

  openProductPage = async (item) => {
    let url = '//' + (this.teamUser.shop.primaryDomain ? this.teamUser.shop.primaryDomain : this.store.platformId + '.cafe24.com');
    if(this.store.platform == 'cafe24') {
      url += '/product/_/' + item.platformProductId;

    }
    else {

    }
    window.open(url);

  };

  onModifyComment = async (item) => {
    item.comment.backContent = item.comment.content;
    item.comment.content = null;
  }

  modalToggle = function(target, data) {
    this.modals[target] = !this.modals[target];
    if(!target) {
      for( var i in this.modals) {
        this.modals[i] = false;
      }
    }
    if(data) {
      this.modals.data = data;
    }
  };

  onChangeSyncReviewValue = async (event, obj) => {
    const { name, value, type } = event.target;
    console.log(name, value, type, obj)
    let newValue;
    if(type == 'checkbox') {
      if(value) {
        newValue = !JSON.parse(value);
      }
      else {
        newValue = true;
      }
    }
    else {
      newValue = value;
    }

    if(obj) {
      obj[name] = newValue;

      if(name == 'all') {
        for(let key in obj) {
          obj[key] = newValue;
        }
      }
      else {
        let isAll = false;
        if(newValue) {
          isAll = true;
          for(let key in obj) {
            if(!obj[key] && key != 'all') {
              isAll = false;
              break;
            }
          }
        }
        obj['all'] = isAll;
      }
    }
    else {
      this.syncReviewValue[name] = newValue;
    }

  };

  onChangeCalendarValueForRS = async (date, dateType) => {
    this.isShowCalendarForRS = {
      startDate: false,
      endDate: false,
    };
    this.syncReviewValue[dateType] = moment(date).format('yyyy-MM-DD');
    if((this.syncReviewValue.startDate || this.syncReviewValue.endDate) && !isOverThreeMonths(this.syncReviewValue.startDate, this.syncReviewValue.endDate)) {
      alert('최대 3개월 단위로 선택해 주세요.');
    }
  }

  togglePopover = async (i) => {
    this.smartCommentPopover[i] = !this.smartCommentPopover[i];
  }

  applyCommentText = async (i, item, text) => {
    item.comment.content = text;
    this.smartCommentPopover[i] = false;
  }

  showReviewMileage = async(item) => {
    let _this = this;
    //console.log(this.reviewMileageConfig.mileageBonusSettings);
    _this.addMileage = {
      type: 'increase',
      reason: '',
      mileageAmout: '',
    };
    this.reviewHistoryListParams.review_id = item._id;
    this.reviewHistoryListParams.type = 'mileage';
    console.log(item)
    if(!item.userOrder_id || !item.userOrder_id._id) {
      return false;
    }
    else {
      this.reviewMileageInfParams = {
        review_id : item._id,
        userOrder_id: item.userOrder_id._id
      };

      this.curReviewHistoryList = await this.props.getReviewHistoryList(this.reviewHistoryListParams);
      this.curReviewMileageInfo = await this.props.getReviewMileageInfo(this.reviewMileageInfParams);
      this.curReviewMileageInfo.payMileageSum = 0;
      for(var i in this.curReviewMileageInfo.userMileageSum) {
        var row = this.curReviewMileageInfo.userMileageSum[i];
        if(row._id === 'increase') {
          this.curReviewMileageInfo.payMileageSum += row.mileageAmout;
        }
        else {
          this.curReviewMileageInfo.payMileageSum -= row.mileageAmout;
        }
      }
      // console.log(this.curReviewMileageInfo, this.curReviewMileageInfo.userMileageHistory)

      //item.mileageInfo = MileageCalculator.total(item, _this.reviewMileageConfig);
      item.mileageInfo = MileageCalculator.total(item, _this.reviewMileageConfig);
      // console.log(item.mil)
      // console.log(item.mileageData)
      console.log(item.mileageInfo)
      this.curReview = item;
      _this.modals.isMileage = true;
    }
  };

  updateMileage = async (mileageInfo, currentReview) => {
    let _this = this;

    if(!mileageInfo.mileageAmout) {
      alert('추가 또는 차감할 마일리지를 입력해 주세요.');
    }
    else if(!mileageInfo.reason) {
      alert('추가 또는 차감할 마일리지를 입력해 주세요.');
    }
    else{
      mileageInfo.mileageAmout = parseInt(mileageInfo.mileageAmout);
      mileageInfo.store_id = _this.teamUser.selStore_id;
      mileageInfo.review_id = currentReview._id;
      mileageInfo.userOrder_id = currentReview.userOrder_id._id;
      //type - increase, decrease

      let result = await this.props.updateMileage(mileageInfo);
      let alertMsg = result.data.reason + ' - 마일리지 '+result.data.mileageAmout;
      if(result.data.type == 'increase') {
        alertMsg += ' 지급 처리 완료';
      }
      else {
        alertMsg += ' 차감 처리 완료';
      }
      toast(alertMsg);
      this.showReviewMileage(this.curReview);
    }


    // if(mileageInfo.mileageAmout > 0 && mileageInfo.reason && mileageInfo.reason.length > 0) {
    //
    // }
    // else {
    //
    // }
  };
  goSetting = async() => {
    window.location.replace("/reviewWriteConfig");
  };

  render() {
    let _this = this;

    function Pagination() {
      let result = [];
      let limit = _this.list.limit;
      let totalPages = _this.list.totalPages;
      let curPageNum = _this.list.page;
      let nextPage = _this.list.nextPage;
      let prevPage = _this.list.prevPage;
      let startNum = (parseInt((curPageNum-1)/10)*10) +1;
      let endNum = startNum+9 < totalPages ? startNum+9 : totalPages;

      let urlParamsArr = new URLSearchParams(window.location.search);
      urlParamsArr.delete('page');
      let urlParams = '?' + urlParamsArr.toString();

      let rendering = () => {
        for(let i = startNum; i <= endNum; i ++) {
          if(i == curPageNum) {
            result.push(<li key={i} className="on"><a href={urlParams + "&page="+i}>{i}</a></li>);
          }
          else {
            result.push(<li key={i}><a href={urlParams + "&page="+i}>{i}</a></li>);
          }
        }
        return result;
      }

      if(_this.list && _this.list.totalPages) {
        return (
            <div className="pagination">
              <ul>
                { curPageNum != 1 ? <li className="btn-page-first"><a href={urlParams + "&page=1"}><FeatherIcon icon="chevrons-left" className="nd-icon" /></a></li> : null}
                { startNum - 1 > 0 ? <li className="btn-page-prev"><a href={urlParams + "&page="+(startNum-1)}><FeatherIcon icon="chevron-left" className="nd-icon" /></a></li> : null}
                {rendering()}
                { endNum != totalPages  ? <li className="btn-page-next"><a href={urlParams + "&page="+(startNum+10)}><FeatherIcon icon="chevron-right" className="nd-icon" /></a></li> : null}
                { curPageNum != totalPages ? <li className="btn-page-last"><a href={urlParams + "&page="+totalPages}><FeatherIcon icon="chevrons-right" className="nd-icon" /></a></li> : null}
              </ul>
            </div>);
      }
      else {
        return (<></>);
      }

    }

    function ReviewStar(props) {
      let goodsPt = props.goodsPt;
      let startClassName = 'review-star star0';
      if(-1 < goodsPt && goodsPt <= 20) {
        startClassName += '1';
      }
      else if(20 < goodsPt && goodsPt <= 40) {
        startClassName += '2';
      }
      else if(40 < goodsPt && goodsPt <= 60) {
        startClassName += '3';
      }
      else if(60 < goodsPt && goodsPt <= 80) {
        startClassName += '4';
      }
      else if(80 < goodsPt && goodsPt <= 100) {
        startClassName += '5';
      }
      return (<div className={startClassName}><span></span></div>);
    }

    function DisplayStatus(props) {
      let statusStr = '';
      let displayStatus = props.displayStatus ? parseInt(props.displayStatus) : null;
      switch (displayStatus){
        case 2 :
          statusStr = '게시 중';
          break;
        case 1 :
          statusStr = '게시 대기';
          break;
        case 90 :
          statusStr = '숨김';
          break;
        default :
          statusStr = displayStatus;
          break;
      }
      return (<div className="posting-state">게시 : <span className="posting-state-txt">{statusStr}</span></div>)
    }

    function OrderStatus(props) {
      let statusStr = '';
      //1:지급예정, 2:지급대기, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급마일리지없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실
      switch (props.orderStatus){
        case 0 :
          statusStr = '입금대기';
          break;
        case 2 :
          statusStr = '지급대기';
          break;
        case 3 :
          statusStr = '비회원 지급대기';
          break;
        case 4 :
          statusStr = '지급완료';
          break;
        case 90 :
          statusStr = '지급취소';
          break;
        case 93 :
          statusStr = '지급마일리지없음';
          break;
        case 91 :
          statusStr = '지급제한';
          break;
        case 81 :
          statusStr = '회수완료';
          break;
        case 95 :
          //쇼핑몰에서 작성된 리뷰
          return false;
          break;
        case 99 :
          statusStr = '회수실패';
          break;
        case 98 :
          statusStr = '지급경고';
          break;
        default :
          statusStr = props.orderStatus;
          break;
      }
      return (<span className="review-state">{statusStr}</span>)
    }



    return (
      <div className="review-mileage main-content" >
        <PageTitle />
        <div className="review-search-box">
          <div className="section-box">

            <div className="search-input-box">
              <span>검색</span>
              <FormSelect disabled={this.isWidgetMode} name="searchType" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.searchType} className="search-type">
                <option value="all">전체</option>
                <option value="review">리뷰내용</option>
                <option value="name">주문자이름</option>
                <option value="id">아이디</option>
              </FormSelect>
              <FormInput disabled={this.isWidgetMode} name="keyword" placeholder="검색어" onChange={event => {this.onChangeSearchValue(event)}} value={this.searchFormValue.keyword} className="table-input-text search-input" onKeyPress={event => {if(event.key === 'Enter') this.doSearch()}}/>
            </div>

            <div className="calendar-box">
              <span>조회기간</span>
              <div className="calendar-wrap">
                <FormInput disabled={this.isWidgetMode} name="startDate" placeholder=""
                           onChange={event => {}}
                           value={this.searchFormValue.startDate}
                           onClick={() => {this.isShowCalendar.endDate = false; this.isShowCalendar.startDate = !this.isShowCalendar.startDate; }}
                           className="table-input-text search-calendar star" /> &#126;
                <FormInput disabled={this.isWidgetMode} name="endDate" placeholder=""
                           onChange={event => {}}
                           value={this.searchFormValue.endDate}
                           onClick={() => {this.isShowCalendar.startDate = false; this.isShowCalendar.endDate = !this.isShowCalendar.endDate; }}
                           className="search-calendar end" />
                {this.isShowCalendar.startDate ? ( <Calendar className="startDate" onChange={date => {this.onChangeCalendarValue(date, 'startDate' )}} value={new Date()} maxDate={new Date()} /> ) : null}
                {this.isShowCalendar.endDate ? (<Calendar className="endDate" onChange={date => {this.onChangeCalendarValue(date, 'endDate' )}} value={moment(this.searchFormValue.endDate).toDate()} maxDate={new Date()} /> ) : null}
              </div>
            </div>
            {!this.isWidgetMode && this.isShowDetailSearch ? (
              <div>
                <div className="detail-filter-box">
                  <table>
                    <tbody>
                    <tr>
                      <th>별점</th>
                      <td>
                        <label><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={this.searchFormValue.star.all} checked={Boolean(this.searchFormValue.star.all)} name="all" type="checkbox" /><span>ALL</span></label>
                        <label className="review-star star05"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star5)} checked={Boolean(this.searchFormValue.star.star5)} name="star5" type="checkbox" /><span></span></label>
                        <label className="review-star star04"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star4)} checked={Boolean(this.searchFormValue.star.star4)} name="star4" type="checkbox" /><span></span></label>
                        <label className="review-star star03"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star3)} checked={Boolean(this.searchFormValue.star.star3)} name="star3" type="checkbox" /><span></span></label>
                        <label className="review-star star02"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star2)} checked={Boolean(this.searchFormValue.star.star2)} name="star2" type="checkbox" /><span></span></label>
                        <label className="review-star star01"><input onChange={event => {this.onChangeSearchValue(event, this.searchFormValue.star)}} value={Boolean(this.searchFormValue.star.star1)} checked={Boolean(this.searchFormValue.star.star1)} name="star1" type="checkbox" /><span></span></label>
                      </td>
                    </tr>
                    <tr>
                      <th>마일리지 지급</th>
                      <td>
                        <label><input type="checkbox" name="paidMileage" onChange={event => {
                          this.onChangeSearchValue(event)
                        }} value={this.searchFormValue.paidMileage}
                                      checked={Boolean(this.searchFormValue.paidMileage)}/><span>지급</span></label>
                        <label><input type="checkbox" name="noPaidMileage" onChange={event => {
                          this.onChangeSearchValue(event)
                        }} value={this.searchFormValue.noPaidMileage}
                                      checked={Boolean(this.searchFormValue.noPaidMileage)}/><span>미지급</span></label>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            <div className="btn-search">
              <Button onClick={event => this.doSearch()}>검색</Button>&nbsp;&nbsp;&nbsp;
              <Button onClick={event=> this.doSearch('reset')}>리셋</Button>
            </div>
            {!this.isWidgetMode ? (
            <div className="btn-toggle" onClick={event=> this.isShowDetailSearch = !this.isShowDetailSearch }><FeatherIcon icon={this.isShowDetailSearch ? "chevron-up" : "chevron-down"} size="37" fill="#ffffff" className="nd-icon"/></div>
            ) : null}
          </div>
        </div>

        <div className="review-list-box">
          <form id="reviewListForm" className="review-list-form">
            <div className="section-box review-list-table">
              <table>
                <colgroup>
                  <col width="10%"/>
                  <col/>
                  <col width="30%"/>
                </colgroup>
                <thead>
                <tr>
                  {/*<th><FormCheckbox*/}
                  {/*  // checked={this.state.orange}*/}
                  {/*  // onChange={e => this.handleChange(e, "orange")}*/}
                  {/*>*/}
                  {/*</FormCheckbox></th>*/}
                  <th>리뷰정보</th>
                  <th>리뷰내용</th>
                  <th>리뷰관리</th>
                </tr>
                </thead>
                <tbody>
                {this.list && this.list.docs && this.list.docs.length > 0 ?
                  this.list.docs.map((item, i) => (
                      <tr id={'review-wrap-' + i} key={i}>
                        <td>
                          <div className="review-info-wrap">
                            <div
                                className="user-name">{item.writerName} {item.writerId ? '(' + item.writerId + ')' : null}
                              {item.isMember ?
                                  (item.isAdmin ? (<span className="member-icon admin">관리자</span>) : (
                                      <span className="member-icon admin">회원</span>))
                                  : ((<span className="member-icon admin">비회원</span>))}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="review-contents-wrap">
                            <div className="order-product-info">
                              {item.product && item.product.image ? (
                                  <div className="product-thumb" onClick={e => this.openProductPage(item)}>
                                    <img src={item.product.image}/>
                                  </div>) : null}
                              {item.orderProductName ? (
                                  <div className="product-info" onClick={e => this.openProductPage(item)}>
                                    <span className="product-name">{item.orderProductName}</span>
                                    <span className="product-option">{item.orderProductOptions}</span>
                                  </div>) : (
                                  <div className="product-info">
                                    {item.product_id && item.product_id.productName ? (<span className="product-name"
                                                                                             onClick={e => this.openProductPage(item)}> {item.product_id.productName}</span>)
                                        : <span className="product-name"> 제품 정보가 없습니다.</span>}
                                  </div>)
                              }
                            </div>
                            <div className="review-contents">
                              <div className="review-contents-box">
                                <div className="review-contents-area">
                                  <div className="review-contents-top">
                                    <span>별점 : </span><ReviewStar goodsPt={item.goodsPt}/><span>/&nbsp;&nbsp;</span>
                                    <span>배송별점 : </span><ReviewStar
                                      goodsPt={item.deliveryPt}/><span>/&nbsp;&nbsp;</span>
                                    <div className="review-write-date">작성 시간
                                      : {moment(item.writerAt).format('YYYY-MM-DD HH:mm')}</div>
                                  </div>
                                  <p dangerouslySetInnerHTML={{__html: item.contents}}/>
                                </div>
                              </div>

                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="review-mileage-ctrl">
                            {item.paidMileage && item.mileageData.mileageAmout ? (<div>
                                  <ul>
                                    <li>적립 마일리지 : {item.paidMileage}</li>
                                    {/*<li>미적립 마일리지 : {item.mileageData.mileageAmout - item.paidMileage}</li>*/}
                                  </ul>
                                </div>)
                                : (
                                    <p>마일리지 정보가 없습니다.</p>
                                )}

                            {item.fromWrite !== 'cafe24' ? (
                                <div className="benefits-state-icon">
                                  <Button size="sm"
                                          onClick={event => this.showReviewMileage(item)}>마일리지 지급 관리</Button>
                                  {/*<FeatherIcon icon="more-horizontal" className="nd-icon" title="마일리지 지급 관리"
                                               id="isReviewHistoryTip" onClick={event => this.showReviewMileage(item)}/>
                                  <Button>
                                      open={this.isReviewHistoryTip}
                                      target="#isReviewHistoryTip"
                                      toggle={e => this.toggleTip('isReviewHistoryTip')}
                                  >
                                    마일리지 지급 관리
                                  </Button>*/}
                                </div>) : null}

                            {item.payMileageStatus !== 95 ? (
                                    <div className="benefits-state-select">
                                      {/*<ButtonGroup>*/}
                                      {/*  <Button disabled={item.payMileageStatus === 90 || item.payMileageStatus === 1 ? false : true} onClick={event => { this.updatePayMileageStatus(item, 4) }}>지급</Button>*/}
                                      {/*  <Button disabled={item.payMileageStatus === 4 ? false : true} onClick={event => { this.updatePayMileageStatus(item, 90) }} theme="warning">회수</Button>*/}
                                      {/*</ButtonGroup>*/}
                                      { /*1:지급예정, 2:지급대기, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급마일리지없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실패 */}
                                      { /*1:지급예정, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급마일리지없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실패 */}
                                      {/*<FormSelect onChange={event => { this.onChangeValueAndUpdate('payMileageStatus', event, item) }} value={item.payMileageStatus} name="payMileageStatus" className="benefits-select" disabled={item.payMileageStatus == 95} >*/}
                                      {/*  <option value="">마일리지 상태 수정하기</option>*/}
                                      {/*  <option value="1">지급예정</option>*/}
                                      {/*  <option value="11">지급대기</option>*/}
                                      {/*  <option value="12">비회원 지급대기</option>*/}
                                      {/*  <option value="2">지급완료</option>*/}
                                      {/*  <option value="9">지급취소</option>*/}
                                      {/*  <option value="91">지급제한</option>*/}
                                      {/*  <option value="90">지급마일리지없음</option>*/}
                                      {/*  <option value="92">회수완료</option>*/}
                                      {/*  <option value="95">비회원</option>*/}
                                      {/*  <option value="99">회수실패</option>*/}
                                      {/*  <option value="98">지급경고</option>*/}
                                      {/*</FormSelect>*/}
                                    </div>
                                ) :
                                (
                                    <div className="benefits-state-select">
                                      처리불가
                                    </div>
                                )
                            }
                          </div>
                        </td>
                      </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={4} className="text-center"><br/><br/>리뷰가 없습니다.<br/><br/><br/><Button size="sm"
                                                                                                        onClick={event => this.goSetting()}>리뷰
                        가져오기</Button><br/><br/></td>
                    </tr>
                }
                </tbody>
              </table>
              <Pagination/>
            </div>
          </form>
        </div>

        {/*<Modal size="lg" className='modal-review-synk-form' open={this.modals.isSyncReview}*/}
        {/*       toggle={this.modalToggle.bind(this)} centered={true}>*/}
        {/*  <ModalHeader>리뷰 가져오기</ModalHeader>*/}
        {/*  <ModalBody className="history-window-popup">*/}
        {/*    /!*<p><span className='warning-font'>* 해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.</span></p>*!/*/}
        {/*    <div className="history-popup-contents">*/}
        {/*      <div className="popup-contents-main">*/}

        {/*        <h4 className="mt-2">작성시간</h4>*/}
        {/*        <Row>*/}
        {/*          <Col md="5">*/}
        {/*            <div className="calendar-box">*/}
        {/*              <div className="calendar-wrap">*/}
        {/*                <FormInput disabled={this.isWidgetMode} name="startDate" placeholder="" onChange={event => {*/}
        {/*                  this.onChangeSyncReviewValue(event)*/}
        {/*                }} value={this.syncReviewValue.startDate} onClick={() => {*/}
        {/*                  this.isShowCalendarForRS.endDate = false;*/}
        {/*                  this.isShowCalendarForRS.startDate = !this.isShowCalendarForRS.startDate;*/}
        {/*                }} className="table-input-text search-calendar star"/>*/}
        {/*                {this.isShowCalendarForRS.startDate ? (*/}
        {/*                    <Calendar value={moment(this.syncReviewValue.startDate).toDate()} className="startDate"*/}
        {/*                              onChange={date => {*/}
        {/*                                this.onChangeCalendarValueForRS(date, 'startDate')*/}
        {/*                              }} maxDate={new Date()}/>) : null}*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </Col>*/}
        {/*          <Col md="1" className="text-center bt-symbol">&#126;</Col>*/}
        {/*          <Col md="5">*/}
        {/*            <div className="calendar-wrap">*/}
        {/*              <FormInput disabled={this.isWidgetMode} name="endDate" placeholder="" onChange={event => {*/}
        {/*                this.onChangeSyncReviewValue(event)*/}
        {/*              }} value={this.syncReviewValue.endDate} onClick={() => {*/}
        {/*                this.isShowCalendarForRS.startDate = false;*/}
        {/*                this.isShowCalendarForRS.endDate = !this.isShowCalendarForRS.endDate;*/}
        {/*              }} className="search-calendar end"/>*/}
        {/*              {this.isShowCalendarForRS.endDate ? (*/}
        {/*                  <Calendar value={moment(this.syncReviewValue.startDate).toDate()} className="endDate" onChange={date => {*/}
        {/*                this.onChangeCalendarValueForRS(date, 'endDate')*/}
        {/*              }} maxDate={new Date()}/>) : null}*/}
        {/*            </div>*/}
        {/*          </Col>*/}
        {/*        </Row>*/}

        {/*        /!*<h4 className="mt-2">추가 방법</h4>*!/*/}
        {/*        /!*<Row>*!/*/}
        {/*        /!*  <Col md="12">*!/*/}
        {/*        /!*    <FormCheckbox toggle name="isOverwrite" checked={this.syncReviewValue.isOverwrite}*!/*/}
        {/*        /!*                  onChange={event => {*!/*/}
        {/*        /!*                    this.syncReviewValue.isOverwrite = !this.syncReviewValue.isOverwrite*!/*/}
        {/*        /!*                  }} value={this.syncReviewValue.isOverwrite}>*!/*/}
        {/*        /!*      {this.syncReviewValue.isOverwrite ? '새로운 리뷰는 추가하며, 기존 리뷰는 수정, 삭제하신 변경사항을 업데이트합니다.' : '새로운 리뷰는 추가하며, 기존 리뷰는 그대로 유지합니다.'}*!/*/}
        {/*        /!*    </FormCheckbox>*!/*/}
        {/*        /!*  </Col>*!/*/}
        {/*        /!*</Row>*!/*/}

        {/*      </div>*/}
        {/*      <div className="text-center">*/}
        {/*        <Button onClick={event => this.setReviewSync('period')}>리뷰가져오기</Button>*/}
        {/*        <Button className="ml-2" theme="light" onClick={event => this.modals.isSyncReview = false}>닫기</Button>*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*  </ModalBody>*/}
        {/*</Modal>*/}

        <Modal size="lg" className='isMileageModal' open={this.modals.isMileage} toggle={this.modalToggle.bind(this)} centered={true}>
          <ModalHeader>리뷰 마일리지 관리 / 내역 [주문상태:<OrderStatus orderStatus={this.curReview.orderStatus}/> / 상품 가격 : {this.curReview.userOrder_id && this.curReview.userOrder_id.orderAmount ? this.curReview.userOrder_id.orderAmount : this.curReview.orderAmount}원]</ModalHeader>
          <ModalBody className="history-window-popup">
            <div className="history-popup-contents">
              <div className="popup-contents-top">
                <h4>마일리지 지급</h4>
                <table>
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                    <col />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>작성자</th>
                    <th>지급 마일리지</th>
                    <th>미지급 마일리지</th>
                    <th>미지급 추가 마일리지</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td className="text-center">{this.curReview.writerName}</td>
                    <td className="text-right">{this.curReview.paidMileage}</td>
                    <td className="text-right">{this.curReview.mileageInfo.unpaid} <Button theme='success' size="sm"
                                                                                           outline
                                                                                           onClick={e => this.updateMileage({
                                                                                             type: 'increase',
                                                                                             mileageAmout: this.curReview.mileageInfo.unpaid,
                                                                                             reason: '상품후기 - ' + this.curReview.orderProductName
                                                                                           }, this.curReview)}>지급</Button>
                    </td>
                    <td className="text-right">
                      {this.curReview.mileageData && this.curReview.mileageData.bonusSum ? this.curReview.mileageData.bonusSum.mileage : 0} &nbsp;
                      <Button theme='success' size="sm" outline onClick={e => this.updateMileage({
                        type: 'increase',
                        mileageAmout: this.curReview.mileageInfo.bonus.bonusOnlyOne.mileage,
                        reason: '상품후기 추가 마일리지 - ' + this.curReview.orderProductName,
                        isBonus: true
                      }, this.curReview)}>지급</Button>
                    </td>

                  </tr>
                  </tbody>
                </table>
                <h4>마일리지 내역</h4>
                <table>
                  <colgroup>
                    <col />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '30%' }} />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>내용</th>
                    <th>마일리지</th>
                    <th>처리 날짜</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.curReviewHistoryList && this.curReviewHistoryList.docs && this.curReviewHistoryList.docs.length > 0 ?
                      this.curReviewHistoryList.docs.map((item, i) => (
                          <tr key={i}>
                            <td>{item.reason}</td>
                            <td className='text-right'>{item.type == 'increase'? '+' : '-'}{item.mileageAmout}</td>
                            <td className='text-right'>{moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                          </tr>
                      )) :
                      <tr>
                        <td colSpan="4">내역이 없습니다.</td>
                      </tr>
                  }
                  {/*{this.curReviewMileageInfo && this.curReviewMileageInfo.userMileageHistory && this.curReviewMileageInfo.userMileageHistory.length > 0 ?*/}
                  {/*    this.curReviewMileageInfo.userMileageHistory.map((item, i) => (*/}
                  {/*        <tr key={i}>*/}
                  {/*          <td>{item.reason}</td>*/}
                  {/*          <td className="text-right">{item.type == 'increase' ? '+' : '-'} {item.mileageAmout}</td>*/}
                  {/*          <td className="text-center">{moment(item.updatedAt).format('YYYY-MM-DD HH:mm')}</td>*/}
                  {/*        </tr>*/}
                  {/*    )) :*/}
                  {/*    <tr>*/}
                  {/*      <td colSpan="4">내역이 없습니다.</td>*/}
                  {/*    </tr>*/}
                  {/*}*/}
                  </tbody>
                </table>
                {this.curReview.mileageData ? (
                <><h4>추가 마일리지{this.curReview.mileageInfo.bonus.bonusOnlyOne.key != "total" ? " (추가 마일리지 항목중 최대 적립금 지급)": "" }</h4>
                <table>
                  {/*<colgroup>*/}
                  {/*  <col style={{ width: '15%' }} />*/}
                  {/*  <col style={{ width: '30%' }} />*/}
                  {/*  <col style={{ width: '30%' }} />*/}
                  {/*  <col />*/}
                  {/*</colgroup>*/}
                  <thead>
                  <tr>
                    <th>장문</th>
                    <th>많은 이미지</th>
                    <th>선착순</th>
                    <th>회원최초</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.curReview.mileageInfo.bonus.bonusOnlyOne.key != "total" ? (
                      <tr>
                        <td>{this.curReview.mileageInfo.bonus.bonusOnlyOne.key === "perText" ? this.curReview.mileageData.bonus.perText : 0}</td>
                        <td>{this.curReview.mileageInfo.bonus.bonusOnlyOne.key === "perImg" ? this.curReview.mileageData.bonus.perImg : 0}</td>
                        <td>{this.curReview.mileageInfo.bonus.bonusOnlyOne.key === "fsb" ? this.curReview.mileageData.bonus.fsb : 0}</td>
                        <td>{this.curReview.mileageInfo.bonus.bonusOnlyOne.key === "joinFirst" ? this.curReview.mileageData.bonus.joinFirst : 0}</td>
                      </tr>
                  ) : (
                      <tr>
                        <td>{this.curReview.mileageData.bonus.perText}</td>
                        <td>{this.curReview.mileageData.bonus.perImg}</td>
                        <td>{this.curReview.mileageData.bonus.fsb}</td>
                        <td>{this.curReview.mileageData.bonus.joinFirst}</td>
                      </tr>
                  )}
                  </tbody>
                </table>
                </>
                ) : null}
                <h4>마일리지 추가 / 차감</h4>
                <table>
                  <colgroup>
                    <col style={{width: '15%'}}/>
                    <col />
                    <col style={{ width: '30%' }} />
                  </colgroup>
                  <thead>
                  <tr>
                    <th>추가/차감</th>
                    <th>내용</th>
                    <th>마일리지</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <FormSelect name="type" onChange={event => {this.onChangeValue(event, this.addMileage)}} value={this.addMileage.type} className="search-type">
                        <option value="increase">추가</option>
                        <option value="decrease">차감</option>
                      </FormSelect>
                    </td>
                    <td><FormInput name="reason" placeholder="" onChange={event => {this.onChangeValue(event, this.addMileage)}} value={this.addMileage.reason} placeholder="내용" theme='success' /></td>
                    <td>
                      <InputGroup>
                        <FormInput name="mileageAmout" placeholder="" onChange={event => {this.onChangeValue(event, this.addMileage)}} value={this.addMileage.mileageAmout} placeholder="마일리지" theme='success' />
                        {/*<FormInput value={this.addMileage} placeholder="추가 마일리지" theme={this.mileagePermisstion ? 'success' : 'secondary'} />*/}
                        <InputGroupAddon type="append">
                          <Button theme='success' onClick={e => this.updateMileage(this.addMileage, this.curReview)}>{this.addMileage.type == 'increase' ? '지급':'차감'}하기</Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div className="popup-close">
                <a href="#" onClick={event=> this.modals.isMileage = false} className="btn-popup-close">닫기</a>
              </div>
            </div>

          </ModalBody>
        </Modal>

        <ModalLoading isLoading={this.isLoading}/>
      </div>
    )
  }
}

export default ReviewMileage;
