import React, {Component} from 'react';
import axios from "axios/index";
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import {Link} from "react-router-dom";
import _ from "underscore";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import moment from "moment/moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';
import {
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  Button, Col, Row,
  ListGroup, ListGroupItem,
  Popover, PopoverBody, PopoverHeader
} from "shards-react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './style.scss'
import ModalLoading from "../../../components/common/ModalLoading";
import {Common as util} from "../../../utils/common";
import DateTimePicker from "react-datetime-picker";

function isOverThreeMonths(date1, date2) {
  const startDate = moment(date1);
  const endDate = moment(date2);
  const monthsDiff = endDate.diff(startDate, 'months');
  return monthsDiff < 3;
}

function isOverPeriod(date1, date2, periodArr) {
  const startDate = moment(date1);
  const endDate = moment(date2);
  const periodDiff = endDate.diff(startDate, periodArr[1]);
  return periodDiff < periodArr[0];
}

@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me }))
@inject(({ common }) => ({ getDoc: common.getDoc, getStoreTotalConfig: common.getStoreTotalConfig }))
@inject(({ review }) => ({ getReviewList:review.getReviewList, setReviewSync: review.setReviewSync,
  updateReviewDisplayStatus: review.updateReviewDisplayStatus, updateReviewSome: review.updateReviewSome, updateReviewComment: review.updateReviewComment, genAIComment: review.genAIComment, commentSmartCorrent: review.commentSmartCorrent, adminDelReview:review.adminDelReview,
  getStatusReviewBoard: review.getStatusReviewBoard }))
@inject(({ widget }) => ({ getWidgetList:widget.getWidgetList, getWidgetInReviewList:widget.getWidgetInReviewList, updateWidgetInReview: widget.updateWidgetInReview, updateWidgetInReviewOrder: widget.updateWidgetInReviewOrder, removeWidgetInReview: widget.removeWidgetInReview }))

@observer
class ReviewList extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  //page
  @observable initialGetReviewList = true;
  @observable isShowDetailSearch = true;

  @observable serarchPeriod = [3, "months", "3개월 단위"];
  @observable searchFormValue = {
    searchType: 'all',
    keyword: '',
    // startDate: moment().add(-7, 'days').format('yyyy-MM-DD'),
    // endDate: moment().format('yyyy-MM-DD'),
    startDate: "",
    endDate: "",
    star: {
      all: true,
      star5: true,
      star4: true,
      star3: true,
      star2: true,
      star1: true,
    },
    writer: {
      all: true,
      member: true,
      admin: true,
    },
    reviewType: {
      all: true,
      text: true,
      image: true,
      // movie: true,
      // survey: true,
    },
    reviewDis: {
      all: true,
      on: true,
      will: true,
      pending: true,
    },
    isDelete: false,
    noComment: false
  };
  @observable widgetList = {};
  @observable syncReviewValue = {
    startDate: moment().add(-1, 'days').format('yyyy-MM-DD'),
    endDate: moment().format('yyyy-MM-DD'),
    isOverwrite: false,
  };
  @observable basicSettings = {};


  @observable isShowCalendar = {
    startDate: false,
    endDate: false
  }

  @observable isShowCalendarForRS = {
    startDate: false,
    endDate: false
  }

  //popover
  @observable smartCommentPopover = {};

  //tooltip
  @observable isReviewHistoryTip = false;
  @observable ismileageHistoryTip = false;

  @observable listParams = {
    page:1, limit:10,
    displayStatus: 100,
    searchParams: {
      displayAt: 'all'
    },
    isPopulateProductReply: true,
    shopNo: 1
  }
  @observable list = {};
  @observable isReplyWrite = false;

  @observable modals = {
    isSyncReview: false,
    data: {}
  };

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeSearchValue = this.onChangeSearchValue.bind(this);
    this.onChangeCalendarValue = this.onChangeCalendarValue.bind(this);
    //this.removeWidgetInReview = this.removeWidgetInReview.bind(this);
    this.onChangeSyncReviewValue = this.onChangeSyncReviewValue.bind(this);
  }

  @action
  init = async (key) => {

    let _this = this;
    if(this.props.isLogin) {
      this.initialGetReviewList = Storage.get('initialGetReviewList') ? Storage.get('initialGetReviewList') : false;
      //basic
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.listParams.shopNo = this.teamUser.selShopNo ? this.teamUser.selShopNo : 1;
      this.isUse = false;
      if(this.teamUser.isPaidShop) {
        this.isUse = true;
      }
      if(!this.props.me || !this.props.me.basicSettings) {
        console.log('null me');
      }
      else {

        let res = await this.props.getStoreTotalConfig({store_id: this.teamUser.selStore_id, shopNo: this.teamUser.selShopNo});
        this.props.me.basicSettings = res.basicSettings
        if(this.props.me.basicSettings && this.props.me.basicSettings.reviewRatingConfigs) {
          this.basicSettings = this.props.me.basicSettings;
          this.basicSettings.reviewRatingConfigs = _.indexBy(this.props.me.basicSettings.reviewRatingConfigs, 'rating');
        }
        else {
          // toast('답글 설정이 되어 있지 않습니다. "리뷰/답글 > 리뷰 설정" 페이지에서 설정을 해주세요.');
          // let isRedirect = window.confirm("리뷰 설정이 되어 있지 않습니다.설정 페이지로 이동하시겠습니까?");
          // if(isRedirect) {
          //   window.location.href = '/reviewWriteConfig';
          // }
        }
      }

      this.store.replyCount = this.props.me.replyCount ? this.props.me.replyCount : {};
      // if(this.store.replyCount.writeCount >= 10) {
      //   alert(util.alertMsg('PAYMENT_WARNING_MESSAGE'));
      //   this.isReplyWrite = false;
      // }
      // else {
      //   this.isReplyWrite = true;
      // }

      this.permissions = this.props.me.permissions;

      this.listParams.store_id = this.teamUser.selStore_id;
      this.urlParamsToListParams();
    }
  };

  urlParamsToListParams = function() {

    let searchFormValueArr = {};
    for (const key of this.urlParams.keys()) {

      if(['page'].indexOf(key) > -1 || ['limit'].indexOf(key) > -1) {
        this.listParams[key] = parseInt(this.urlParams.get(key));
      }
      else {
        if(key.indexOf('__') > -1) {
          let keyArr = key.split('__');
          if(!searchFormValueArr[keyArr[0]]) { searchFormValueArr[keyArr[0]] = {};}
          searchFormValueArr[keyArr[0]][keyArr[1]] = true;
        }
        else {
          this.searchFormValue[key] = this.urlParams.get(key);
          this.listParams.searchParams[key] = this.urlParams.get(key);
        }
      }
    }

    for(let key in searchFormValueArr) {
      if(searchFormValueArr[key].all) {
        for(let deKey in this.searchFormValue[key]) {
          this.searchFormValue[key][deKey] = true;
        }
      }
      else {
        for(let deKey in this.searchFormValue[key]) {
          if(searchFormValueArr[key][deKey]) {
            this.searchFormValue[key][deKey] = true;
          }
          else {
            this.searchFormValue[key][deKey] = false;
          }
        }
      }
      this.listParams.searchParams[key] = searchFormValueArr[key];
    }

    this.getWidgetList();
    this.getReviewList();
  }


  //ui fn
  doSearch = async(isReset) => {
    if(isReset) {
      window.location = window.location.pathname;
    }
    else {
      this.searchFormValue.searchType = 'search';
      let urlParams = new URLSearchParams();
      if(this.searchFormValue['widget_id']) {
        urlParams.set('widget_id', this.searchFormValue['widget_id']);
      }
      else {
        for(let key in this.searchFormValue) {
          if(key != 'store_id' && key != 'teamUserId' && key != 'limit' && this.searchFormValue[key]) {
            if(typeof this.searchFormValue[key] == 'object') {
              if(!this.searchFormValue[key]['all']) {
                for(let depthKey in this.searchFormValue[key]) {
                  if(this.searchFormValue[key][depthKey]) {
                    urlParams.set(key + '__' +depthKey, this.searchFormValue[key][depthKey]);
                  }
                }
              }
              else {
                urlParams.set(key + '__all', this.searchFormValue[key]['all']);
              }

            }
            else {
              urlParams.set(key, this.searchFormValue[key]);
            }
          }
        }
      }
      window.location.search = urlParams.toString();
    }

  };

  onChangeSearchValue = async (event, obj) => {
    const { name, value, type } = event.target;
    let newValue;
    if(type == 'checkbox') {
      if(value) {
        newValue = !JSON.parse(value);
      }
      else {
        newValue = true;
      }
    }
    else {
      newValue = value;
    }

    if(obj) {
      console.log(obj, newValue)
      obj[name] = newValue;

      if(name == 'all') {
        for(let key in obj) {
          obj[key] = newValue;
        }
      }
      else {
        let isAll = false;
        if(newValue) {
          isAll = true;
          for(let key in obj) {
            if(!obj[key] && key != 'all') {
              isAll = false;
              break;
            }
          }
        }
        obj['all'] = isAll;
      }
    }
    else {
      this.searchFormValue[name] = newValue;
      if(name == 'isDelete') {
        for(let key in this.searchFormValue.reviewDis) {
          this.searchFormValue.reviewDis[key] = !newValue;
        }
      }
    }

  };

  searchPeriodUnit = async (type) => {
    switch (type) {
      case "all" :
        this.searchFormValue.startDate = "";
        this.searchFormValue.endDate = "";
        break;

      default :

        break;

    }




  }

  onChangeCalendarValue = async (date, dateType) => {
    this.isShowCalendar = {
      startDate: false,
      endDate: false,
    };
    this.searchFormValue[dateType] = moment(date).format('yyyy-MM-DD');
  };

  onChangeValue =  async (event, targetObj) => {
    const { name, value } = event.target;
    console.log(targetObj)
    targetObj[name] = value;
  };

  // onChangeValueAndUpdate = async (type, event, item) => {
  //
  //   const { name, value } = event.target;
  //   if(!value || value == '') {
  //     return false;
  //   }
  //   let params = {};
  //
  //   let result;
  //   params = {
  //     id: item._id,
  //     preStatus: item.displayStatus
  //   };
  //   params[name] = parseInt(value);
  //   result = await this.props.updateReviewDisplayStatus(params);
  //   toast('게시 상태가 변경되었습니다.');
  //   item[name] = params[name];
  //
  // };


  onChangeValueAndUpdate = async (type, event, item) => {

    const { name, value } = event.target;
    if(!value || value == '') {
      return false;
    }
    let params = {};

    let result;
    if(type === 'widget_id') {
      let updateWidgetId = value;
      params = {
        review_id: item._id,
        widget_id: value,
      };

      if(item.widget_ids && item.widget_ids.indexOf(params.widget_id) > -1) {
        // todo 이미 해당 리뷰의 위젯이 추가된 상황
        toast('이미 위젯이 추가 되었있습니다.');
        // params.reviewWidgetRef_ids = item.reviewWidgetRef_ids;
        // //todo reviewWidgetRef 해당 값 가져오기
        // params.review_ids = [item._id]
      }
      else {
        this.props.updateWidgetInReview(params, function(res) {
          if(!item.widget_ids) {
            item.widget_ids = [];
          }
          item.widget_ids.push(params.widget_id);
        });
      }
    }
    else if(type == 'payMileageStatus') {
      if(item.userOrder_id && item.userOrder_id._id) {
        params = {
          review_id: item._id,
          userOrder_id: item.userOrder_id._id
        };
        params[name] = parseInt(value);
        result = await this.props.updatePayMileage(params, function(res) {

        });
      }
      else {
        toast('해당 리뷰는 포인트 처리가 불가능합니다.');
      }

    }
    else {
      params = {
        id: item._id,
        preStatus: item.displayStatus
      };
      params[name] = parseInt(value);
      alert('게시 상태변경에 따른 포인트 지불 또는 회수 처리는 수동으로 처리 하셔야 합니다.');
      result = await this.props.updateReviewDisplayStatus(params);
      console.log(result)
      toast('게시 상태가 변경되었습니다.');
      item[name] = params[name];
    }
  };

  toggleTip = async (target) => {
    this[target] = !this[target];
  }

  //page fn
  getWidgetList = async() => {
    if(this.listParams.store_id) {
      let params = {page:1, limit:20, store_id:this.listParams.store_id, selecting:'specify'}
      let widgetList = await this.props.getWidgetList(params);
      this.widgetList = _.indexBy(widgetList.docs, '_id');
      //console.log(Object.keys(this.widgetList).length);
    }
    else {
      //console.log('null store_id');
    }
  };

  getReviewList = async() => {
    if(this.listParams.store_id) {
      let res = await this.props.getReviewList(this.listParams);
      if(!this.searchFormValue.startDate && !this.searchFormValue.endDate && this.initialGetReviewList === false && res.totalDocs === 0) {
        let cf = window.confirm('설정 및 상품, 리뷰 데이터를 연동하시겠습니까?');
        if(cf) {
          Storage.set('initialGetReviewList', true);
          //this.setReviewSync('latest');
          window.location.replace("/reviewWriteConfig");
        }
      }
      _.map(res.docs, function(row) {
        row.product = row.product_id;
        return row;
      });
      this.list = res;
      this.isLoading = false;
    }
    else {
      //console.log('null store_id');
    }
  };

  setReviewSync = async (type) => {
    let _this = this;
    //let confimRes = window.confirm("기존 온리플의 리뷰는 모두 삭제되고 카페24 리뷰로 대체 됩니다. 진행하시겠습니까?");
    _this.isLoading = true;
    let data = {
      store_id: _this.store._id,
      getType: type ? type : 'new',

    }
    if(type == 'period') {
      if(isOverPeriod(this.syncReviewValue.startDate, this.syncReviewValue.endDate, this.serarchPeriod)) {
        data.params = this.syncReviewValue;
      }
      else {
        alert('최대 ' + this.serarchPeriod[3]  + '로 선택해 주세요.');
        return false;
      }
    }


    _this.props.setReviewSync(data, function(res) {
      if(res && res.data) {
        toast(moment(res.data.startDate).format('YYYY-MM-DD') + ' ~ ' + moment(res.data.endDate).format('YYYY-MM-DD') + ' 기간의 리뷰가 ' +  res.data.insertReviewCount + '개 업데이트 되었습니다.');
        if(res.data.insertReviewCount > 0) {
          _this.getReviewList();
        }
      }
      _this.isLoading = false;
    });

  };

  reviewContentModifyToggle = (item) => {

    if(!item.isEdit) {
      item.orgContents = item.contents;
      item.isEdit = true;
    }
    else {
      item.contents = item.orgContents;
      item.isEdit = false;
    }

  };

  updateReviewContent = async (item) => {
    let result = await this.props.updateReviewComment({id:item._id, comment:{comment: item.comment.content}});
    if(result.e) {
      let cf = window.confirm('답글을 작성하기 위한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
      if(cf) {
        window.location.href = '/reviewWriteConfig';
      }
    }
    item.isEdit = false;
  };

  genAIComment = async (item) => {
    let _this = this;
    let params = {
      store_id: item.store_id,
      review_id: item.id
    }
    if(!item.comment) {
      item.comment = {};
    }
    item.isWriteReady = true;
    let orgContent = item.comment.content;
    item.comment.content = 'AI 답글 생성중.';
    let intervalId = setInterval(() => {
      item.comment.content = item.comment.content + '.';
    }, 200);
    let result = await this.props.genAIComment(params);
    if(result && result.error && result.error.message) {
      item.comment.content = orgContent;
      if(result.error.message === "null storeBasicSettings.") {
       let cf = window.confirm('해당 작업은 답글 작성에 대한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
       if(cf) {
         window.location.href = '/reviewWriteConfig';
       }
      }
      else{
        let cf = window.confirm(result.error.message + '설정 페이지로 이동하시겠습니까?');
        if(cf) {
          window.location.href = '/reviewWriteConfig';
        }
      }
    }
    else {
      if(result.data && result.data.content) {
        item.comment.content = result.data.content;
      }
      else if(result.data && result.data.overWriteCount) {
        alert(util.alertMsg('PAYMENT_WARNING_MESSAGE'));
        this.store.replyCount.writeCount = 10;
        this.isReplyWrite = false;
      }
    }
    clearInterval(intervalId);
    item.isWriteReady = false;

  };

  genFixComment = async (item, comment) => {
    if(!this.basicSettings.writer) {
        let cf = window.confirm('답글을 작성하기 위한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
        if(cf) {
          window.location.href = '/reviewWriteConfig';
        }
    }
    let updateComment = comment.replace(/\[구매자명\]/g, item.writerName ? util.nameUnexpos(item.writerName) : '고객님')
        .replace(/\[상품명\]/g, item.product && item.product.productName ? item.product.productName : '상품')
        .replace(/\[쇼핑몰명\]/g, this.basicSettings && this.basicSettings.writer ? this.basicSettings.writer : '저희 쇼핑몰')
        .replace(/\[상품별 추가 내용\]\n?/g, item.vr_productReply && item.vr_productReply.tone ? item.vr_productReply.tone : '');
    if(!item.comment) {
      item.comment = {};
    }
    item.comment.content = updateComment;


  }

  commentSmartCorrent = async (item, i) => {
    this.smartCommentPopover[i] = true;
    if(!item.smartComments || !item.smartComments.comment_modify) {
      let params = {
        store_id: item.store_id,
        review_id: item.id,
        comment:item.comment.content
      }
      item.smartComments = {};
      item.smartCommentsReady = '답글을 분석중입니다';
      let intervalId = setInterval(() => {
        item.smartCommentsReady = item.smartCommentsReady + '.';
      }, 200);
      let result = await this.props.commentSmartCorrent(params);
      if(result && result.data) {
        clearInterval(intervalId);
        if(result.data.e) {
          item.smartCommentsReady = "실패하였습니다. 다시 시도하여 주세요."
        }
        else {
          item.smartCommentsReady = '답글 분석 완료';
          item.smartComments = result.data.content;
        }
      }
    }

  };

  updateComment = async (item) => {
    let _this = this;
    console.log(item.comment)
    if(!item.comment || !item.comment.content || item.comment.content == '') {
      alert('답글을 입력해 주세요.');
      return false;
    }
    let result = await this.props.updateReviewComment({id:item._id, comment:{comment: item.comment.content, article_no: item.comment.article_no}});
    if(result && result.data) {
      if(result.data.e) {
        let cf = window.confirm('답글을 작성하기 위한 설정이 필요합니다. 설정 페이지로 이동하시겠습니까?');
        if(cf) {
          window.location.href = '/reviewWriteConfig';
        }
      }
      else {
        toast('답글이 작성되었습니다.');
      }
    }

  };


  delReview = async (item) => {
    let _this = this;
    let message = '리뷰 삭제시 지급한 포인트는 카페24 관리자에서 처리 하셔야 하며, 삭제 후 복구 불가능합니다. 삭제 진행 하시겠습니까?';
    if(item.isAdmin) {
      message = '리뷰를 정말 삭제 하시겠습니까?'
    }
    let result = window.confirm(message);
    if(result) {
      let params = {
        review_id: item._id,
        store_id:item.store_id
      };
      this.props.adminDelReview(params, function(res) {
        console.log(res);
        toast('리뷰가 삭제되었습니다.');
        _this.getReviewList();
      });
    }

  }

  openProductPage = async (item) => {
    let url = '//' + (this.teamUser.shop.primaryDomain ? this.teamUser.shop.primaryDomain : this.store.platformId + '.cafe24.com');
    if(this.store.platform == 'cafe24') {
      url += '/product/_/' + item.platformProductId;

    }
    else {

    }
    window.open(url);

  };

  onModifyComment = async (item) => {
    item.comment.backContent = item.comment.content;
    item.comment.content = null;
  }

  removeWidgetInReview = async(item, widget_id, indexObj) => {
    let _this = this;
    let params = {
      widget_id: widget_id,
      review_id: item._id
    };

    this.props.removeWidgetInReview(params, function(res) {
      if(_this.searchFormValue.widget_id && _this.searchFormValue.widget_id === widget_id) {
        _this.list.docs.splice(indexObj.review, 1);
      }
      else {
        item.widget_ids.splice(indexObj.widget, 1);
      }
    });

  };


  modalToggle = function(target, data) {
    let _this = this;
    for( var i in this.modals) {
      this.modals[i] = false;
    }
    this.modals[target] = !this.modals[target];
    if(target === "isSyncReview") {
      let data = {
        store_id: this.store._id,
        flatform: 'cafe24'
      }

      this.props.getStatusReviewBoard(data, function(res) {

        if(res && res.data) {
          console.log(res.data);
          _this.modals.data = res.data;
          if(res.data.selectPeriod[1] === "years") {
            res.data.selectPeriod[2] = res.data.selectPeriod[0] + "년 단위";
          }
          else if(res.data.selectPeriod[1] === "months") {
            res.data.selectPeriod[2] = res.data.selectPeriod[0] + "월 단위";
          }
          else if(res.data.selectPeriod[1] === "days") {
            res.data.selectPeriod[2] = res.data.selectPeriod[0] + "일 단위";
          }
          _this.serarchPeriod = res.data.selectPeriod;
        }
      });
    }

    if(data) {
      this.modals.data = data;
    }
  };

  onChangeSyncReviewValue = async (event, obj) => {
    const { name, value, type } = event.target;
    console.log(name, value, type, obj)
    let newValue;
    if(type == 'checkbox') {
      if(value) {
        newValue = !JSON.parse(value);
      }
      else {
        newValue = true;
      }
    }
    else {
      newValue = value;
    }

    if(obj) {
      obj[name] = newValue;

      if(name == 'all') {
        for(let key in obj) {
          obj[key] = newValue;
        }
      }
      else {
        let isAll = false;
        if(newValue) {
          isAll = true;
          for(let key in obj) {
            if(!obj[key] && key != 'all') {
              isAll = false;
              break;
            }
          }
        }
        obj['all'] = isAll;
      }
    }
    else {
      this.syncReviewValue[name] = newValue;
    }

  };

  onChangeCalendarValueForRS = async (date, dateType) => {
    this.isShowCalendarForRS = {
      startDate: false,
      endDate: false,
    };
    this.syncReviewValue[dateType] = moment(date).format('yyyy-MM-DD');
    if((this.syncReviewValue.startDate || this.syncReviewValue.endDate) && !isOverPeriod(this.syncReviewValue.startDate, this.syncReviewValue.endDate, this.serarchPeriod)) {
      alert('최대 ' + this.serarchPeriod[3]  + '로 선택해 주세요.');

    }
  }

  togglePopover = async (i) => {
    this.smartCommentPopover[i] = !this.smartCommentPopover[i];
  }

  applyCommentText = async (i, item, text) => {
    item.comment.content = text;
    this.smartCommentPopover[i] = false;
  }

  onChangeChecked = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
      if(keyArray[0] === 'isFixed') {
        this.props.updateReviewSome({id:targetObj._id, isFixed:targetObj.isFixed}, function(res) {
          console.log(res);
          toast('리뷰가 업데이트 되었습니다.');
        });
      }
    }
  };

  render() {
    let _this = this;

    function Pagination() {
      let result = [];
      let limit = _this.list.limit;
      let totalPages = _this.list.totalPages;
      let curPageNum = _this.list.page;
      let nextPage = _this.list.nextPage;
      let prevPage = _this.list.prevPage;
      let startNum = (parseInt((curPageNum-1)/10)*10) +1;
      let endNum = startNum+9 < totalPages ? startNum+9 : totalPages;

      let urlParamsArr = new URLSearchParams(window.location.search);
      urlParamsArr.delete('page');
      let urlParams = '?' + urlParamsArr.toString();

      let rendering = () => {
        for(let i = startNum; i <= endNum; i ++) {
          if(i == curPageNum) {
            result.push(<li key={i} className="on"><a href={urlParams + "&page="+i}>{i}</a></li>);
          }
          else {
            result.push(<li key={i}><a href={urlParams + "&page="+i}>{i}</a></li>);
          }
        }
        return result;
      }

      if(_this.list && _this.list.totalPages) {
        return (
            <div className="pagination">
              <ul>
                { curPageNum != 1 ? <li className="btn-page-first"><a href={urlParams + "&page=1"}><FeatherIcon icon="chevrons-left" className="nd-icon" /></a></li> : null}
                { startNum - 1 > 0 ? <li className="btn-page-prev"><a href={urlParams + "&page="+(startNum-1)}><FeatherIcon icon="chevron-left" className="nd-icon" /></a></li> : null}
                {rendering()}
                { endNum != totalPages  ? <li className="btn-page-next"><a href={urlParams + "&page="+(startNum+10)}><FeatherIcon icon="chevron-right" className="nd-icon" /></a></li> : null}
                { curPageNum != totalPages ? <li className="btn-page-last"><a href={urlParams + "&page="+totalPages}><FeatherIcon icon="chevrons-right" className="nd-icon" /></a></li> : null}
              </ul>
            </div>);
      }
      else {
        return (<></>);
      }

    }

    function ReviewStar(props) {
      let goodsPt = props.goodsPt;
      let startClassName = 'review-star star0';
      if(-1 < goodsPt && goodsPt <= 20) {
        startClassName += '1';
      }
      else if(20 < goodsPt && goodsPt <= 40) {
        startClassName += '2';
      }
      else if(40 < goodsPt && goodsPt <= 60) {
        startClassName += '3';
      }
      else if(60 < goodsPt && goodsPt <= 80) {
        startClassName += '4';
      }
      else if(80 < goodsPt && goodsPt <= 100) {
        startClassName += '5';
      }
      return (<div className={startClassName}><span></span></div>);
    }

    function DisplayStatus(props) {
      let statusStr = '';
      let displayStatus = props.displayStatus ? parseInt(props.displayStatus) : null;
      switch (displayStatus){
        case 2 :
          statusStr = '게시 중';
          break;
        case 1 :
          statusStr = '게시 대기';
          break;
        case 90 :
          statusStr = '숨김';
          break;
        default :
          statusStr = displayStatus;
          break;
      }
      return (<div className="posting-state">게시 : <span className="posting-state-txt">{statusStr}</span></div>)
    }

    function PayMileageStatus(props) {
      let statusStr = '';
      let bonusStatusStr = '';
      let payMileageStatus = props.payMileageStatus ? parseInt(props.payMileageStatus) : null;
      let payBonusMileageStatus = props.payBonusMileageStatus ? parseInt(props.payBonusMileageStatus) : null;

      //1:지급예정, 2:지급대기, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급포인트없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실패
      switch (payMileageStatus){
        case 1 :
          statusStr = '지급예정';
          break;
        case 2 :
          statusStr = '지급대기';
          break;
        case 3 :
          statusStr = '비회원 지급대기';
          break;
        case 4 :
          statusStr = '지급완료';
          // props.item.mileageInfo = MileageCalculator.total(props.item, _this.reviewmileageConfig);
          // console.log(props.item.mileageInfo)
          break;
        case 90 :
          statusStr = '지급취소';
          // props.item.mileageInfo = MileageCalculator.total(props.item, _this.reviewmileageConfig);
          // console.log(props.item.mileageInfo)
          break;
        case 92 :
          statusStr = '지급제한';
          break;
        case 81 :
          statusStr = '회수완료';
          break;
        case 82 :
          statusStr = '회수실패';
          break;
        case 93 :
          statusStr = '지급포인트없음';
          break;
        case 95 :
          statusStr = '쇼핑몰 관리자에서 확인';
          break;
        case 99 :
          statusStr = '회수실패';
          break;
        case 98 :
          statusStr = '지급경고';
          break;
        default :
          statusStr = payMileageStatus;
          break;
      }

      switch (payBonusMileageStatus){
        case 1 :
          bonusStatusStr = '지급예정';
          break;
        case 2 :
          bonusStatusStr = '지급대기';
          break;
        case 3 :
          bonusStatusStr = '비회원 지급대기';
          break;
        case 4 :
          bonusStatusStr = '지급완료';
          // props.item.mileageInfo = MileageCalculator.total(props.item, _this.reviewmileageConfig);
          // console.log(props.item.mileageInfo)
          break;
        case 90 :
          bonusStatusStr = '지급취소';
          // props.item.mileageInfo = MileageCalculator.total(props.item, _this.reviewmileageConfig);
          // console.log(props.item.mileageInfo)
          break;
        case 92 :
          bonusStatusStr = '지급제한';
          break;
        case 81 :
          bonusStatusStr = '회수완료';
          break;
        case 82 :
          bonusStatusStr = '회수실패';
          break;
        case 93 :
          bonusStatusStr = '지급포인트없음';
          break;
        case 95 :
          bonusStatusStr = '쇼핑몰 관리자에서 확인';
          break;
        case 99 :
          bonusStatusStr = '회수실패';
          break;
        case 98 :
          bonusStatusStr = '지급경고';
          break;
        default :
          bonusStatusStr = payBonusMileageStatus ? '('+payBonusMileageStatus+')' : '';
          break;
      }
      if(bonusStatusStr && bonusStatusStr != '') {
        bonusStatusStr = ' / (보너스)' + bonusStatusStr;
      }
      return (<div className="benefits-state">포인트 : <span className="benefits-state-txt">{statusStr}{bonusStatusStr}</span></div>)
    }

    function PayMileageStatusRow(props) {
      let statusStr = '';
      let statusDateStr = '-';
      switch (props.payMileageStatus){
        case 1 :
          statusStr = '지급예정';
          break;
        case 11 :
          statusStr = '지급대기';
          break;
        case 12 :
          statusStr = '비회원 지급대기';
          break;
        case 2 :
          statusStr = '지급완료';
          break;
        case 9 :
          statusStr = '지급취소';
          break;
        case 90 :
          statusStr = '지급포인트없음';
          break;
        case 91 :
          statusStr = '지급제한';
          break;
        case 92 :
          statusStr = '회수완료';
          break;
        case 93 :
          statusStr = '회수완료';
          break;
        case 95 :
          statusStr = '쇼핑몰 관리자에서 확인 요망';
          break;
        case 99 :
          statusStr = '회수실패';
          break;
        case 98 :
          statusStr = '지급경고';
          break;
        default :
          statusStr = props.payMileageStatus;
          break;
      }
      return (<span className="review-state-time">{statusStr}</span>)
    }

    function OrderStatus(props) {
      let statusStr = '';
      //1:지급예정, 2:지급대기, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급포인트없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실
      switch (props.orderStatus){
        case 0 :
          statusStr = '입금대기';
          break;
        case 2 :
          statusStr = '지급대기';
          break;
        case 3 :
          statusStr = '비회원 지급대기';
          break;
        case 4 :
          statusStr = '지급완료';
          break;
        case 90 :
          statusStr = '지급취소';
          break;
        case 93 :
          statusStr = '지급포인트없음';
          break;
        case 91 :
          statusStr = '지급제한';
          break;
        case 81 :
          statusStr = '회수완료';
          break;
        case 95 :
          //쇼핑몰에서 작성된 리뷰
          return false;
          break;
        case 99 :
          statusStr = '회수실패';
          break;
        case 98 :
          statusStr = '지급경고';
          break;
        default :
          statusStr = props.orderStatus;
          break;
      }
      return (<span className="review-state">{statusStr}</span>)
    }


    return (
      <div className="main-content" >
        <PageTitle />
        {/*<Row className="mtb-10 text-right">*/}
        {/*  <Col>*/}
        {/*    <Button size="sm" disabled={!this.isUse} onClick={e => this.modalToggle('isSyncReview')} >기존 리뷰 가져오기</Button>&nbsp;&nbsp;*/}
        {/*    /!*<Button size="sm" disabled={!this.isUse} onClick={event=> this.setReviewSync('latest')}>최신 리뷰 가져오기</Button>*!/*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <div className="review-search-box">
          <div className="section-box">
            <div className="search-input-box">
              <span>위젯</span>
              <FormSelect name="widget_id" onChange={event => {
                this.onChangeSearchValue(event)
              }} value={this.searchFormValue.widget_id} className="search-type">
                <option value="">-위젯 선택하기-</option>
                {this.widgetList ?
                    Object.keys(this.widgetList).map((key, i) => (
                        <option key={i} value={key}>{this.widgetList[key].title}</option>
                    ))
                    : null
                }
              </FormSelect>
            </div>

            <div className="search-input-box">
              <span>검색</span>
              <FormSelect disabled={this.isWidgetMode} name="searchType" onChange={event => {
                this.onChangeSearchValue(event)
              }} value={this.searchFormValue.searchType} className="search-type">
                <option value="all">전체</option>
                <option value="review">리뷰내용</option>
                <option value="name">주문자이름</option>
                <option value="id">아이디</option>
              </FormSelect>
              <FormInput disabled={this.isWidgetMode} name="keyword" placeholder="검색어" onChange={event => {
                this.onChangeSearchValue(event)
              }} value={this.searchFormValue.keyword} className="table-input-text search-input" onKeyPress={event => {
                if (event.key === 'Enter') this.doSearch()
              }}/>
            </div>

            <div className="calendar-box">
              <span>조회기간</span>
              <div className="calendar-wrap">
                <FormInput disabled={this.isWidgetMode} name="startDate" placeholder=""
                           onChange={event => {
                           }}
                           value={this.searchFormValue.startDate}
                           onClick={() => {
                             this.isShowCalendar.endDate = false;
                             this.isShowCalendar.startDate = !this.isShowCalendar.startDate;
                           }}
                           className="table-input-text search-calendar star"/> &#126;
                <FormInput disabled={this.isWidgetMode} name="endDate" placeholder=""
                           onChange={event => {
                           }}
                           value={this.searchFormValue.endDate}
                           onClick={() => {
                             this.isShowCalendar.startDate = false;
                             this.isShowCalendar.endDate = !this.isShowCalendar.endDate;
                           }}
                           className="search-calendar end"/>
                {this.isShowCalendar.startDate ? (<Calendar className="startDate" onChange={date => {
                  this.onChangeCalendarValue(date, 'startDate')
                }} value={new Date()} />) : null}
                {this.isShowCalendar.endDate ? (<Calendar className="endDate" onChange={date => {
                  this.onChangeCalendarValue(date, 'endDate')
                }} value={new Date()} />) : null}
                <Button className="btns_period" onClick={event => this.searchPeriodUnit('all')}>전체</Button>
              </div>
            </div>
            {!this.isWidgetMode && this.isShowDetailSearch ? (
                <div>
                  <div className="detail-filter-box">
                    <table>
                      <colgroup>
                        <col style={{width: '15%'}}/>
                        <col style={{width: '35%'}}/>
                        <col style={{width: '15%'}}/>
                        <col style={{width: '35%'}}/>
                      </colgroup>
                      <tbody>
                      <tr>
                        <th>별점</th>
                        <td colSpan={3}>
                          <label><input onChange={event => {
                            this.onChangeSearchValue(event, this.searchFormValue.star)
                          }} value={this.searchFormValue.star.all} checked={Boolean(this.searchFormValue.star.all)}
                                        name="all" type="checkbox"/><span>ALL</span></label>
                          <label className="review-star star05"><input onChange={event => {
                            this.onChangeSearchValue(event, this.searchFormValue.star)
                          }} value={Boolean(this.searchFormValue.star.star5)}
                                                                       checked={Boolean(this.searchFormValue.star.star5)}
                                                                       name="star5"
                                                                       type="checkbox"/><span></span></label>
                          <label className="review-star star04"><input onChange={event => {
                            this.onChangeSearchValue(event, this.searchFormValue.star)
                          }} value={Boolean(this.searchFormValue.star.star4)}
                                                                       checked={Boolean(this.searchFormValue.star.star4)}
                                                                       name="star4"
                                                                       type="checkbox"/><span></span></label>
                          <label className="review-star star03"><input onChange={event => {
                            this.onChangeSearchValue(event, this.searchFormValue.star)
                          }} value={Boolean(this.searchFormValue.star.star3)}
                                                                       checked={Boolean(this.searchFormValue.star.star3)}
                                                                       name="star3"
                                                                       type="checkbox"/><span></span></label>
                          <label className="review-star star02"><input onChange={event => {
                            this.onChangeSearchValue(event, this.searchFormValue.star)
                          }} value={Boolean(this.searchFormValue.star.star2)}
                                                                       checked={Boolean(this.searchFormValue.star.star2)}
                                                                       name="star2"
                                                                       type="checkbox"/><span></span></label>
                          <label className="review-star star01"><input onChange={event => {
                            this.onChangeSearchValue(event, this.searchFormValue.star)
                          }} value={Boolean(this.searchFormValue.star.star1)}
                                                                       checked={Boolean(this.searchFormValue.star.star1)}
                                                                       name="star1"
                                                                       type="checkbox"/><span></span></label>
                        </td>
                      </tr>
                      <tr>
                        <th>답글 작성 유무</th>
                        <td>
                          <label><input type="checkbox" name="noComment" onChange={event => {
                            this.onChangeSearchValue(event)
                          }} value={this.searchFormValue.noComment}
                                        checked={Boolean(this.searchFormValue.noComment)}/><span>미작성</span></label>
                        </td>
                        <th>상단 고정 리뷰</th>
                        <td>
                          <label><input type="checkbox" name="isFixed" onChange={event => {
                            this.onChangeSearchValue(event)
                          }} value={this.searchFormValue.isFixed}
                                        checked={Boolean(this.searchFormValue.isFixed)}/><span>상단 고정 리뷰만 보기</span></label>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            ) : null}
            <div className="btn-search">
              <Button onClick={event => this.doSearch()}>검색</Button>&nbsp;&nbsp;&nbsp;
              <Button onClick={event => this.doSearch('reset')}>리셋</Button>
            </div>
            {!this.isWidgetMode ? (
                <div className="btn-toggle" onClick={event => this.isShowDetailSearch = !this.isShowDetailSearch}>
                  <FeatherIcon icon={this.isShowDetailSearch ? "chevron-up" : "chevron-down"} size="37" fill="#ffffff"
                               className="nd-icon"/></div>
            ) : null}
          </div>
        </div>

        <div className="review-list-box">
          <form id="reviewListForm" className="review-list-form">
            <div className="section-box review-list-table">
              <table>
                <colgroup>
                  <col width="10%"/>
                  <col/>
                  <col width="12%"/>
                  <col width="15%"/>
                </colgroup>
                <thead>
                <tr>
                  {/*<th><FormCheckbox*/}
                  {/*  // checked={this.state.orange}*/}
                  {/*  // onChange={e => this.handleChange(e, "orange")}*/}
                  {/*>*/}
                  {/*</FormCheckbox></th>*/}
                  <th>리뷰정보</th>
                  <th>리뷰내용</th>
                  <th>위젯</th>
                  <th>리뷰관리</th>
                </tr>
                </thead>
                <tbody>
                {this.list && this.list.docs && this.list.docs.length > 0 ?
                  this.list.docs.map((item, i) => (
                      <tr id={'review-wrap-' + i} key={i}>
                        <td>
                          <div className="review-info-wrap">

                              {item.isMember ?
                                  (item.isAdmin ? (
                                      <div className="user-name">{item.writerName} {item.writerId ? '(' + item.writerId + ')' : null}
                                        <span className="member-icon admin">관리자</span>
                                      </div>

                                  ) : (
                                      <div className="user-name"><a href={"https://" + this.teamUser.platformId + ".cafe24.com/admin/php/shop1/c/member_admin_d_f.php?user_id=onnomad"} target="_blank">{item.writerName} {item.writerId ? '(' + item.writerId + ')' : null}</a>
                                        <span className="member-icon admin">회원</span>
                                      </div>
                                        ))
                                  : ((
                                      <div className="user-name">{item.writerName} {item.writerId ? '(' + item.writerId + ')' : null}
                                        <span className="member-icon admin">비회원</span>
                                      </div>
                                  ))}

                          </div>
                        </td>
                        <td>
                          <div className="review-contents-wrap">
                            <div className="order-product-info">
                              {item.product && item.product.image ? (
                                  <div className="product-thumb" onClick={e => this.openProductPage(item)}>
                                    <img src={item.product.image}/>
                                  </div>) : null}
                              {item.orderProductName ? (
                                  <div className="product-info" onClick={e => this.openProductPage(item)}>
                                    <span className="product-name">{item.orderProductName}</span>
                                    <span className="product-option">{item.orderProductOptions}</span>
                                  </div>) : (
                                  <div className="product-info">
                                    {item.product_id && item.product_id.productName ? (<span className="product-name"
                                                                                             onClick={e => this.openProductPage(item)}> {item.product_id.productName}</span>)
                                        : <span className="product-name"> 제품 정보가 없습니다.</span>}
                                  </div>)
                              }
                            </div>
                            <div className="review-contents">
                              <div className="review-contents-box">
                                <div className="review-contents-area">
                                  <div className="review-contents-top">
                                    <span>별점 : </span><ReviewStar goodsPt={item.goodsPt}/><span>/&nbsp;&nbsp;</span>
                                    {item.deliveryPt ? (<>
                                    <span>배송별점 : </span><ReviewStar
                                      goodsPt={item.deliveryPt}/><span>/&nbsp;&nbsp;</span>
                                    </>) : null}
                                    <div className="review-write-date">작성 시간
                                      : {moment(item.writerAt).format('YYYY-MM-DD HH:mm')}</div>
                                  </div>
                                  <div className="review-content">
                                    {!item.isEdit ? (
                                        <p dangerouslySetInnerHTML={{__html: item.contents}}/>
                                    ) : (
                                        <FormGroup>
                                          <FormTextarea className="review-content-textarea" name="contents"
                                                        value={item.contents ? item.contents : ''} onChange={event => {
                                            item.contents = item.contents ? item.contents : {};
                                            this.onChangeValue(event, item)
                                          }}/>
                                          <button onClick={e => this.updateReviewContent(item)} type="button"
                                                  className="btn-review-content-modify">수정
                                          </button>
                                        </FormGroup>
                                    )
                                    }
                                  </div>
                                </div>
                                <div className="review-photo-area">
                                  <div className="review-photo">
                                    <ul>
                                      {/*  {item.movies && item.movies.length > 0 ?*/}
                                      {/*    item.movies.map((item, j) => (*/}
                                      {/*      <li key={j}>*/}
                                      {/*        {typeof item == 'object' ? (<img src={"https://customer-9lljf7xk3rrg9o6q.cloudflarestream.com/" + item.src + "/thumbnails/thumbnail.gif?width=150&height=150&time=1s&duration=5s"} alt="" />) : <img src={"https://customer-9lljf7xk3rrg9o6q.cloudflarestream.com/" + item + "/thumbnails/thumbnail.gif?width=150&height=150&time=1s&duration=5s"} alt="" /> }*/}
                                      {/*      </li>*/}
                                      {/*    ))*/}
                                      {/*    : null*/}
                                      {/*  }*/}
                                      {item.images && item.images.length > 0 ?
                                          item.images.map((item, j) => (
                                              <li key={j}>
                                                {typeof item == 'object' ? (<img src={item.src + "/thumba"} alt=""/>) :
                                                    <img src={item + "/thumba"} alt=""/>}
                                              </li>
                                          ))
                                          : null
                                      }
                                      {item.platformImages && item.platformImages.length > 0 ?
                                          item.platformImages.map((item, j) => (
                                              <li key={j}>
                                                {item.path ? (<img src={item.path} alt=""/>) :
                                                    <img src={item.url} alt=""/>}
                                              </li>
                                          ))
                                          : null
                                      }
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className={'review-comment'}>
                                {item.comment && item.comment.content ?
                                    <FeatherIcon onClick={e => this.onModifyComment(item)} icon="x-square"
                                                 className="nd-ico btn-erase"/> : null}
                                {this.basicSettings && this.basicSettings.reviewRatingConfigs ? (
                                <div className="reply-content active">
                                  {(() => {
                                    let resultHtml = '';
                                    let type;
                                    let fixComment;
                                    switch (item.goodsPt) {
                                      case 20:
                                        if (this.basicSettings.reviewRatingConfigs['1_20']) {
                                          type = this.basicSettings.reviewRatingConfigs['1_20'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['1_20'].tone;
                                        } else if (this.basicSettings.reviewRatingConfigs['1_40']) {
                                          type = this.basicSettings.reviewRatingConfigs['1_40'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['1_40'].tone;
                                        }
                                        break;
                                      case 40:
                                        if (this.basicSettings.reviewRatingConfigs['21_40']) {
                                          type = this.basicSettings.reviewRatingConfigs['21_40'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['21_40'].tone;
                                        } else if (this.basicSettings.reviewRatingConfigs['1_40']) {
                                          type = this.basicSettings.reviewRatingConfigs['1_40'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['1_40'].tone;
                                        }
                                        break;
                                      case 60:
                                        if (this.basicSettings.reviewRatingConfigs['41_60']) {
                                          type = this.basicSettings.reviewRatingConfigs['41_60'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['41_60'].tone;
                                        } else if (this.basicSettings.reviewRatingConfigs['41_100']) {
                                          type = this.basicSettings.reviewRatingConfigs['41_100'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['41_100'].tone;
                                        }
                                        break;
                                      case 80:
                                        if (this.basicSettings.reviewRatingConfigs['61_80']) {
                                          type = this.basicSettings.reviewRatingConfigs['61_80'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['61_80'].tone;
                                        } else if (this.basicSettings.reviewRatingConfigs['41_100']) {
                                          type = this.basicSettings.reviewRatingConfigs['41_100'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['41_100'].tone;
                                        }
                                        break;
                                      case 100:
                                        if (this.basicSettings.reviewRatingConfigs['81_100']) {
                                          type = this.basicSettings.reviewRatingConfigs['81_100'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['81_100'].tone;
                                        } else if (this.basicSettings.reviewRatingConfigs['41_100']) {
                                          type = this.basicSettings.reviewRatingConfigs['41_100'].type;
                                          fixComment = this.basicSettings.reviewRatingConfigs['41_100'].tone;
                                        }
                                        break;
                                      default:
                                    }
                                    if (this.isUse && type === 'ai') {
                                      resultHtml = (<span onClick={e => this.genAIComment(item)}
                                                          className="btn-review-comment-top">AI답글 생성하기</span>);
                                    } else if (this.isUse && type === 'fixed') {
                                      resultHtml = (<span onClick={e => this.genFixComment(item, fixComment)}
                                                          className="btn-review-comment-top">고정답글 가져오기</span>);
                                    } else if (this.isUse && type === 'na') {
                                      resultHtml = (<span className="btn-review-comment-top">직접작성</span>);
                                    } else if (this.isUse) {
                                      resultHtml = (<span className="btn-review-comment-top">미설정<a
                                          href="/reviewWriteConfig#reviewRatingConfigs" target="_blank">(리뷰별점 답글마법사 설정 가기)</a></span>);
                                    }
                                    return resultHtml;
                                  })()}
                                  {this.isUse ? (
                                          <FormGroup>
                                            <FormTextarea name="content"
                                                          id={"popover-" + i}
                                                          value={item.comment && item.comment.content ? item.comment.content : ''}
                                                          onChange={event => {
                                                            item.comment = item.comment ? item.comment : {};
                                                            this.onChangeValue(event, item.comment)
                                                          }}
                                                          placeholder="답글을 작성해 주세요."/>
                                            <button onClick={e => this.commentSmartCorrent(item, i)} type="button"
                                                    className="btn-smart-correct">답글<br/>다듬기
                                            </button>
                                            <Popover
                                                className="smart-correct-popover"
                                                placement="bottom"
                                                container={'#review-wrap-' + i}
                                                open={this.smartCommentPopover[i]}
                                                // toggle={event => {this.togglePopover(i)} }
                                                target={"#popover-" + i}
                                            >
                                              <PopoverHeader>스마트 교정/추천 <span onClick={event => {
                                                this.togglePopover(i)
                                              }}>닫기</span></PopoverHeader>
                                              <PopoverBody>
                                                {item.smartComments && (item.smartComments.comment_modify || item.smartComments.comment_suggestion) ? (
                                                        <ListGroup>
                                                          <ListGroupItem className="comment-modify"
                                                                         onClick={e => this.applyCommentText(i, item, item.smartComments.comment_modify ? item.smartComments.comment_modify : '')}>
                                                            <h4>교정 답글</h4>
                                                            <div
                                                                className="comment">{item.smartComments.comment_modify ? item.smartComments.comment_modify : ''}</div>
                                                          </ListGroupItem>
                                                          {item.smartComments && item.smartComments.comment_suggestion ?
                                                              Object.keys(item.smartComments.comment_suggestion).map((j) => (
                                                                  <ListGroupItem key={j}
                                                                                 onClick={e => this.applyCommentText(i, item, item.smartComments.comment_suggestion[j])}>
                                                                    <h4>추천 답글</h4>
                                                                    <div
                                                                        className="comment">{item.smartComments.comment_suggestion[j]}</div>
                                                                  </ListGroupItem>
                                                              )) : null}
                                                        </ListGroup>
                                                    ) :
                                                    <div className="ready">
                                                      {item.smartCommentsReady ? item.smartCommentsReady : '답글을 분석중입니다.'}
                                                    </div>}

                                              </PopoverBody>
                                            </Popover>

                                            {item.isWriteReady ? <button type="button" disabled={true}
                                                                         className="btn-review-comment">생성중</button> :
                                                <button onClick={e => this.updateComment(item)} type="button"
                                                        className="btn-review-comment">저장</button>
                                            }
                                          </FormGroup>) :
                                      <FormGroup>
                                        <FormTextarea name="content"
                                                      id={"popover-" + i}
                                                      value={item.comment && item.comment.content ? item.comment.content : ''}
                                                      disabled={true}
                                                      onChange={event => {
                                                        item.comment = item.comment ? item.comment : {};
                                                        this.onChangeValue(event, item.comment)
                                                      }}
                                                      placeholder="답글을 작성해 주세요."/>
                                      </FormGroup>
                                  }
                                </div>) : null}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="review-widget-wrap">
                            <div className="review-widget-list">
                              <ul>
                                {item.widget_ids && item.widget_ids.length > 0 ?
                                    item.widget_ids.map((key, j) => (
                                        <li key={j}>
                                          <div className="review-widget">
                                            <span
                                                className="review-widget-tit">{this.widgetList[key] ? this.widgetList[key].title : '???'}</span>
                                            <span className="review-widget-del"
                                                  onClick={event => this.removeWidgetInReview(item, key, {
                                                    review: i,
                                                    widget: j
                                                  })}>
                                        <FeatherIcon icon="x" className="nd-icon"/>
                                      </span>
                                          </div>
                                        </li>
                                    ))
                                    : <div className="no-data">지정된 위젯이 없습니다.</div>
                                }
                              </ul>
                            </div>
                            <div className="review-widget-select">
                              <FormSelect name="widget_id" onChange={event => {
                                item.widget_id = item.widget_id ? item.widget_id : {};
                                this.onChangeValueAndUpdate('widget_id', event, item)
                              }} className="search-type">
                                <option value="">-위젯 선택하기-</option>
                                {this.widgetList ?
                                    Object.keys(this.widgetList).map((key, i) => (
                                        <option key={i} value={key}>{this.widgetList[key].title}</option>
                                    ))
                                    : null
                                }
                              </FormSelect>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="review-management">
                            <Row>
                              <Col md={{size:11, offset:1}}>
                                <FormCheckbox toggle small checked={item.isFixed}
                                              onChange={event => this.onChangeChecked(event, item, ['isFixed'])}>상단 고정
                                </FormCheckbox>
                              </Col>
                            </Row>
                            <div className="posting-state-box">
                              <DisplayStatus displayStatus={item.displayStatus}/>
                              <div className="posting-state-select">
                                <FormSelect name="displayStatus" className="posting-select" onChange={event => {
                                  this.onChangeValueAndUpdate('displayStatus', event, item)
                                }} value={item.displayStatus}>
                                  <option value="">게시 상태 수정하기</option>
                                  <option value="2">게시 중</option>
                                  <option value="1">게시 대기</option>
                                  <option value="90">숨김</option>
                                </FormSelect>
                              </div>
                            </div>

                            <div className="benefits-state-box">
                              {item.syncFrom == 'cafe24' ? (<p>카페24 관리자에서 확인</p>)
                                  : (<PayMileageStatus item={item} payMileageStatus={item.payMileageStatus}
                                                     payBonusMileageStatus={item.payBonusMileageStatus}/>)}

                              {item.syncFrom != 'cafe24' ? (
                                  <div className="benefits-state-icon">
                                    <FeatherIcon icon="more-horizontal" className="nd-icon" title="히스토리 보기"
                                                 id="isReviewHistoryTip" onClick={event => this.showReviewMileage(item)}/>
                                    <Tooltip
                                        open={this.isReviewHistoryTip}
                                        target="#isReviewHistoryTip"
                                        toggle={e => this.toggleTip('isReviewHistoryTip')}
                                    >
                                      게시 및 포인트 관리
                                    </Tooltip>
                                  </div>) : null}

                              <div className="benefits-state-select">
                                {/*<ButtonGroup>*/}
                                {/*  <Button disabled={item.payMileageStatus == 90 || item.payMileageStatus == 1 ? false : true} onClick={event => { this.updatePayMileageStatus(item, 4) }}>지급</Button>*/}
                                {/*  <Button disabled={item.payMileageStatus == 4 ? false : true} onClick={event => { this.updatePayMileageStatus(item, 90) }} theme="warning">회수</Button>*/}
                                {/*</ButtonGroup>*/}
                                { /*1:지급예정, 2:지급대기, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급포인트없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실패 */}
                                { /*1:지급예정, 3: 비회원 지급대기, 4: 지급완료, 90: 지급취소, 91: 지급경고, 92: 지급제한, 93: 지급포인트없음, 95: 관리자에서 확인요망, 81: 회수완료, 82: 회수실패 */}
                                {/*<FormSelect onChange={event => { this.onChangeValueAndUpdate('payMileageStatus', event, item) }} value={item.payMileageStatus} name="payMileageStatus" className="benefits-select" disabled={item.payMileageStatus == 95} >*/}
                                {/*  <option value="">포인트 상태 수정하기</option>*/}
                                {/*  <option value="1">지급예정</option>*/}
                                {/*  <option value="11">지급대기</option>*/}
                                {/*  <option value="12">비회원 지급대기</option>*/}
                                {/*  <option value="2">지급완료</option>*/}
                                {/*  <option value="9">지급취소</option>*/}
                                {/*  <option value="91">지급제한</option>*/}
                                {/*  <option value="90">지급포인트없음</option>*/}
                                {/*  <option value="92">회수완료</option>*/}
                                {/*  <option value="95">쇼핑몰 관리자에서 확인</option>*/}
                                {/*  <option value="99">회수실패</option>*/}
                                {/*  <option value="98">지급경고</option>*/}
                                {/*</FormSelect>*/}
                              </div>
                            </div>

                            <div className="review-management-tool">
                              <ul>
                                <li className={'review-top' + (this.isWidgetMode && i > 0 ? '' : ' disabled')}
                                    onClick={event => {
                                      this.onChangeWidgetOrder(item, i, 'up');
                                    }}>
                                  <FeatherIcon icon="chevrons-up" className="nd-icon"/>
                                </li>
                                <li className={'review-down' + (this.isWidgetMode && i < this.list.docs.length - 1 ? '' : ' disabled')}
                                    onClick={event => {
                                      this.onChangeWidgetOrder(item, i, 'down');
                                    }}>
                                  <FeatherIcon icon="chevrons-down" className="nd-icon"/>
                                </li>
                                {/*<li className="review-modify disabled" onClick={ event => { this.reviewContentModifyToggle(item); }}>*/}
                                {/*  <FeatherIcon icon="edit-2" className="nd-icon" />*/}
                                {/*</li>*/}
                                <li className="review-modify disabled">
                                  <FeatherIcon icon="edit-2" className="nd-icon"/>
                                </li>

                                <li className="review-memo" onClick={event => {
                                  this.delReview(item)
                                }}>
                                  <FeatherIcon icon="trash-2" className="nd-icon"/>
                                </li>
                              </ul>
                            </div>

                          </div>
                        </td>
                      </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={4} className="text-center"><br/><br/>리뷰가 없습니다.<br/><br/><br/></td>
                    </tr>
                }
                </tbody>
              </table>
              <Pagination/>
            </div>
          </form>
        </div>

        <Modal size="lg" className='modal-review-synk-form' open={this.modals.isSyncReview}
               toggle={this.modalToggle.bind(this)} centered={true}>
          <ModalHeader>리뷰 가져오기</ModalHeader>
          <ModalBody className="history-window-popup">
            {/*<p><span className='warning-font'>* 해당 리뷰작성에 대한 책임은 작성자 또는 작성자가 속한 회사에 책임이 있습니다.</span></p>*/}
            <div className="history-popup-contents">
              <div className="popup-contents-main">

                <h4 className="mt-2">작성시간 ({'최대 ' + this.serarchPeriod[2]  + '로 선택해 주세요.'})</h4>
                <Row>
                  <Col md="5">
                    <div className="calendar-box">
                      <div className="calendar-wrap">
                        <FormInput disabled={this.isWidgetMode} name="startDate" placeholder="" onChange={event => {
                          this.onChangeSyncReviewValue(event)
                        }} value={this.syncReviewValue.startDate} onClick={() => {
                          this.isShowCalendarForRS.endDate = false;
                          this.isShowCalendarForRS.startDate = !this.isShowCalendarForRS.startDate;
                        }} className="table-input-text search-calendar star"/>
                        {this.isShowCalendarForRS.startDate ? (
                            <Calendar value={moment(this.syncReviewValue.startDate).toDate()} className="startDate"
                                      onChange={date => {
                                        this.onChangeCalendarValueForRS(date, 'startDate')
                                      }} maxDate={new Date()}/>) : null}
                      </div>
                    </div>
                  </Col>
                  <Col md="1" className="text-center bt-symbol">&#126;</Col>
                  <Col md="5">
                    <div className="calendar-wrap">
                      <FormInput disabled={this.isWidgetMode} name="endDate" placeholder="" onChange={event => {
                        this.onChangeSyncReviewValue(event)
                      }} value={this.syncReviewValue.endDate} onClick={() => {
                        this.isShowCalendarForRS.startDate = false;
                        this.isShowCalendarForRS.endDate = !this.isShowCalendarForRS.endDate;
                      }} className="search-calendar end"/>
                      {this.isShowCalendarForRS.endDate ? (
                          <Calendar value={moment(this.syncReviewValue.startDate).toDate()} className="endDate" onChange={date => {
                        this.onChangeCalendarValueForRS(date, 'endDate')
                      }} maxDate={new Date()}/>) : null}
                    </div>
                  </Col>
                </Row>

                <h4 className="mt-2">추가 방법</h4>
                <Row>
                  <Col md="12">
                    <FormCheckbox toggle name="isOverwrite" checked={this.syncReviewValue.isOverwrite}
                                  onChange={event => {
                                    this.syncReviewValue.isOverwrite = !this.syncReviewValue.isOverwrite
                                  }} value={this.syncReviewValue.isOverwrite}>
                      {this.syncReviewValue.isOverwrite ? '새로운 리뷰는 추가하며, 기존 리뷰는 수정, 삭제하신 변경사항을 업데이트합니다.' : '새로운 리뷰는 추가하며, 기존 리뷰는 그대로 유지합니다.'}
                    </FormCheckbox>
                  </Col>
                </Row>

              </div>
              <div className="text-center">
                <Button onClick={event => this.setReviewSync('period')}>리뷰가져오기</Button>
                <Button className="ml-2" theme="light" onClick={event => this.modals.isSyncReview = false}>닫기</Button>
              </div>
            </div>

          </ModalBody>
        </Modal>

        <ModalLoading isLoading={this.isLoading}/>
      </div>
    )
  }
}

export default ReviewList;
