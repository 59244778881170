import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import _ from "underscore";
import ReactDom from 'react-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './teamUserInfoEdit.scss';



import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';

//inport images
import noImg from "../../../images/img/no_img.jpg";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import history from "../../../utils/history";


const PopupDom = ({ children }) => {
  const el = document.getElementById('popupDom');
  return ReactDom.createPortal(children, el);
};

@inject(({ common }) => ({ sendMail: common.sendMail, testCafe24Api: common.testCafe24Api }))
@inject(({ teamUser }) => ({ authCheck: teamUser.authCheck, isLogin: teamUser.isLogin, me:teamUser.me, updateTeamUser:teamUser.updateTeamUser, updatePassword:teamUser.updatePassword,
  sendEmailValid: teamUser.sendEmailValid, emailValided: teamUser.emailValided, withdrawal:teamUser.withdrawal }))

@observer
class TeamUserInfoEdit extends Component {

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);

  //page
  @observable isSendEmail = false;
  @observable emailValidNum;
  @observable inputEmailValidNum;
  @observable isEditPassword = false;
  @observable isPopupOpen = false;

  constructor(props) {
    super(props);
    this.init()

    this.onChangeValue = this.onChangeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.withdrawal = this.withdrawal.bind(this);
    this.updateTeamUser = this.updateTeamUser.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.openPostCode = this.openPostCode.bind(this);
    this.closePostCode = this.closePostCode.bind(this);

  }


  componentDidUpdate(prevProps, prevState){
    // console.log(prevProps)
    // console.log(prevState)

  }

  @action
  init = async (key) => {
    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.teamUser.email = this.teamUser.email ? this.teamUser.email : this.teamUser.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};
      // if(!this.teamUser.emailValid) {
      //   alert('이메일을 인증해 주세요.');
      // }
      // if(!this.store._id) {
      //   toast('회사 정보를 입력해 주세요.');
      //   this.props.history.push('/companyInfo');
      // }

      this.permissions = this.props.me.permissions;
    }
    else {
      if(Storage.get('token')) {
        //todo 재확인 - 로그인 후 해당 페이지로 넘어 오는 경우 authCheck() 함수 미샐행 할 수 있게.
        //this.props.authCheck();
      }
      // else {
      //   window.location.replace('signIn');
      // }

    }
  };

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    if(name == 'inputEmailValidNum') {
      this[name] = value;
    }
    if(name == 'password') {
      this.teamUser[name] = value;
    }
    else {
      this.teamUser[name] = value;
    }
  };

  sendEmailValid = async (event) => {
    let _this = this;

    _this.isSendEmail = true;
    this.props.sendEmailValid(this.teamUser, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        _this.emailValidNum = res.data.validNum;
        toast('메일로 인증 메일이 발송 되었습니다.');
      }
    });
  }

  sendMail = async (event) => {
    let _this = this;
    this.props.sendMail({mailType: 'welcome'}, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        console.log(res);
      }
    });
  }

  checkValidNum = async (event) => {
    let _this = this;
    if(!this.emailValidNum) {
      alert('이메일 인증 생성을 다시 해주세요.');
      //this.isSendEmail = false;
      return false;
    }
    else if(!this.inputEmailValidNum || this.emailValidNum != this.inputEmailValidNum) {
      console.log(_this.inputEmailValidNum)
      alert('인증 번호를 정확하게 입력해 주세요.');
      return false;
    }
    else {
      this.teamUser.validNum = this.inputEmailValidNum;
      this.props.emailValided(this.teamUser, function(res) {
        if(res.errors && res.errors.length > 0) {
          alert(res.errors[0].message);
        }
        else {
          _this.teamUser.emailValid = true;
        }

      });
    }
  }

  updateTeamUser = async (event) => {
    let _this = this;
    if(!this.teamUser.name || this.teamUser.name == '') {
      alert("담당자명을 입력해 주세요.");
      return false;
    }

    delete this.teamUser.selShopNo;
    this.props.updateTeamUser(this.teamUser, function(res) {
      if(res.errors && res.errors.length > 0) {
        if(res.errors[0].message == 'fail password') {
          alert("입력하신 현재 비밀번호를 확인해 주세요.");
        }
        else {
          alert(res.errors[0].message);
        }
      }
      else {
        _this.isEditPassword = false;
        toast("관리자 정보 업데이트 완료 되었습니다.");
      }

    });

  };

  updatePassword = async (event) => {
    let _this = this;
    // if(!this.teamUser.password || this.teamUser.password == '') {
    //   alert("비밀번호를 확인해 주세요.");
    //   return false;
    // }

    if(this.teamUser.newPassword !== this.teamUser.passwordConfirm) {
      alert("새로운 비밀번호를 확인해 주세요.");
      return false;
    }

    this.props.updatePassword(this.teamUser, function(res) {
      if(res.errors && res.errors.length > 0) {
        if(res.errors[0].message == 'fail password') {
          alert("입력하신 현재 비밀번호를 확인해 주세요.");
        }
        else {
          alert(res.errors[0].message);
        }
      }
      else {
        _this.isEditPassword = false;
        toast("비밀번호가 재설정 되었습니다.");

      }
    });

  };

  openPostCode = async (event) => {
    this.isPopupOpen = true;
  }

  // 팝업창 닫기
  closePostCode = async (event) => {
    this.isPopupOpen = false;
  }

  onSubmit = async (event) => {
    event.preventDefault();
    this.updateTeamUser(event)
    // console.log(this.teamUser)
    //this.props.doSignup(params)
  };

  withdrawal = async (event) => {
    if(!this.teamUser.password || this.teamUser.password == '') {
      alert("비밀번호를 확인해 주세요.");
      return false;
    }
    let confirm = window.confirm('탈퇴하시면 회원정보가 모두 삭제 됩니다. 진행하시겠습니까?');

    if(confirm) {
      let reason = window.prompt('탈퇴사유를 입력해 주세요.');
      if(reason && reason != '') {
        this.teamUser.reason = reason;
        this.props.withdrawal(this.teamUser);
      }

    }

  };

  testCafe24Api = async (event) => {
    let _this = this;
    this.props.testCafe24Api({params: 'welcome'}, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        console.log(res);
      }
    });
  }

  render() {


    return (
      <div className="main-content">
        <PageTitle />
        {/*<div>*/}
        {/*  <button type="button" onClick={e => {this.sendMail();} } >메일발송</button>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <button type="button" onClick={e => {this.testCafe24Api();} } >카페24 api 확인용(테스트)</button>*/}
        {/*</div>*/}
        {( this.isLogin && this.teamUser.emailId ) ?
          <div className="basic-info-box">
            <form onSubmit={e => this.onSubmit(e)}>

              <div className="section-box basic-info-table">
                <div className="section-tit">
                  <h3>유저 정보</h3>
                  <p> - 정확한 정보를 입력해 주세요. 부정확한 정보 입력 시 서비스 이용이 제한될 수 있으니 유의해 주시기 바랍니다.<br />
                    더 나은 서비스를 제공하기 위해 협조 부탁드립니다. 감사합니다.</p>
                </div>
                <table>
                  <tbody>

                  <tr className="info-id">
                    <th>담당자명</th>
                    <td>
                      <input name="name" type="text" className="table-input-text" defaultValue={this.teamUser.name} onChange={e => this.onChangeValue(e)} />
                    </td>
                  </tr>
                  <tr className="info-id">
                    <th>아이디</th>
                    <td>
                      <input name="emailId" type="text" className="table-input-text" defaultValue={this.teamUser.emailId} disabled />
                    </td>
                  </tr>
                  {(!this.isEditPassword) ? <>
                  <tr className="info-password hidden-cont">
                    <th>비밀번호</th>
                    <td>

                      { this.isEditPassword ?
                          <>
                            {/*<input name="password" type="password" onChange={e => this.onChangeValue(e)} />*/}
                            {/*<p className="guide-text">초기 비밀번호는 하단 이메일 주소입니다.</p>*/}
                          </>
                        : <button type="button" className="btn-table btn-info-register" onClick={e => {this.isEditPassword = true;} } >수정하기</button> }

                    </td>
                  </tr>
                  </> : null }
                  {(this.isEditPassword) ? <>
                    <tr className="info-password hidden-cont">
                      <th>수정 할 비밀번호</th>
                      <td>
                        <input name="newPassword" type="password" onChange={e => this.onChangeValue(e)} />
                      </td>
                    </tr>
                    <tr className="info-password hidden-cont">
                      <th>수정 할 비밀번호 확인</th>
                      <td>
                        <input name="passwordConfirm" type="password" onChange={e => this.onChangeValue(e)} />
                        {/*<div className="btn-password-type">*/}
                        {/*  <FeatherIcon icon="eye" className="nd-icon" />*/}
                        {/*</div>*/}
                        <button type="button" className="btn-table btn-info-register" onClick={e => this.updatePassword(e)}>비밀번호 업데이트</button>
                        <button type="button" className="btn-table btn-info-register" onClick={e => {this.isEditPassword = false;}}>취소</button>
                      </td>
                    </tr>
                  </> : null }
                  <tr className="info-phone">
                    <th>전화번호</th>
                    <td>
                      <input name="tel" type="text" className="table-input-text" defaultValue={this.teamUser.tel} onChange={e => this.onChangeValue(e)} />
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>
                      <input name="email" type="text" className="table-input-text" defaultValue={this.teamUser.email} onChange={e => this.onChangeValue(e)} />
                      { !this.teamUser.emailValid ?
                        (this.isSendEmail ?
                          <>
                          <input name="inputEmailValidNum" type="text" className="table-input-text" placeholder="인증번호" onChange={e => this.onChangeValue(e)} />
                          <button type="button" className="btn-table btn-info-register" onClick={e => {this.checkValidNum();} } >인증하기</button>
                          </>
                        : <button type="button" className="btn-table btn-info-register" onClick={e => {this.sendEmailValid();} } >인증번호받기</button> ) : null }
                      <p className="guide-text">결제 안내, 서비스 이용안내 등을 받아보게 될 관리자 이메일 주소 입니다.</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div className="btn-save">
                <input type="submit" value="저장" className="save-button" />
              </div>


              {/*<div className="withdrawal">*/}
              {/*  <button type="button" className="btn-withdrawal" onClick={e => {this.withdrawal(e);}}>탈퇴하기</button>*/}
              {/*</div>*/}
            </form>
          </div>
          :
          <p className='empty'>편집할 질문지 템플릿을 선택하시거나 '새로만들기' 버튼을 클릭하세요.</p>
        }

      </div>
    )
  }
}

export default TeamUserInfoEdit;