import React, { useState, useEffect } from "react";
import { Tooltip } from "shards-react";

import "./SignOut.scss";
import iconKakao from "../images/btn_kakao.png";

const SignOutLayout = ({ children }) => {
    const [kakaoChat, setKakaoChat] = useState(false);
    const [tooltipTarget, setTooltipTarget] = useState(null);

    useEffect(() => {
        // 컴포넌트가 마운트된 후에 #kakaoChat 요소를 찾음
        setTooltipTarget(document.getElementById("kakaoChat"));
        setKakaoChat(true); // Tooltip 항상 표시
    }, []);

    return (
        <div className="wrap-sign-out">
            {children}
            <div id="kakaoChat" className="btn-kakao-ch">
                <a href="https://pf.kakao.com/_Tsxopxb/chat" target="_blank" rel="noopener noreferrer">
                    <img src={iconKakao} alt="Kakao Chat" />
                </a>
            </div>
            {tooltipTarget && (
                <Tooltip open={kakaoChat} target="#kakaoChat" placement={"bottom"}>
                    <div className="wrap-sign-out-tooltip">
                        상담 가능 시간:
                        <br /> 10:00 AM - 5:00 PM
                        <br /> 주말 및 공휴일은 상담이 어렵습니다.
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default SignOutLayout;