import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Store } from "../../flux";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {
  Button,
  Col, FormSelect, Row,
} from "shards-react";
import moment from "moment";

import './Header.scss'
import {Link} from "react-router-dom"
import history from "../../utils/history";
import logo from "../../images/img/logo_v2.png";
import imgBrand from "../../images/avatars/brand_60.png";
import {toast} from "react-toastify";


@inject(({ common }) => ({ getCurNav: common.getCurNav }))
@inject(({ teamUser }) => ({ isLogin: teamUser.isLogin, me:teamUser.me, doLogOut:teamUser.doLogOut, updateTeamUser:teamUser.updateTeamUser }))
@inject(({ company }) => ({ getApplyShop:company.getApplyShop, updateStore:company.updateStore }))

@observer
class Header extends React.Component {

  @observable store = {};
  @observable teamUser = {};
  @observable shops = [];
  @observable emailId = '';
  @observable isLogin = false;
  @observable isShowSetting = false;

  constructor(props) {
    super(props);
    this.init();
    this.onChange = this.onChange.bind(this);
  }

  @action
  init = async (key) => {

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.emailId = this.props.me.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};
      this.store.replyCount = this.props.me.replyCount ? this.props.me.replyCount : {};
      if(this.store.replyCount.writeCount === undefined) {
        this.store.replyCount.writeCount = 0;
      }
      this.getApplyShops(false);
    }

  };

  getApplyShops = async (actSync) => {
    let _this = this;
    let updateStore = {
      store_id: this.teamUser.store._id
    }
    this.props.getApplyShop( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        toast(res.errors[0].message);
      }
      else {
        _this.shops = res.data;
        if(actSync) {
          toast('업데이트가 완료 되었습니다.');
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState){

  }

  componentDidMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }

  onChangeShop = async (event) => {
    const { name, value } = event.target;
    let shopNo = value ? parseInt(value) : 1;
    this.teamUser.selShopNo = shopNo;

    let updateStore = {
      id: this.teamUser._id,
      selShopNo: shopNo,
    }

    this.props.updateTeamUser( updateStore, function(res) {
      if(res.errors && res.errors.length > 0) {
        alert(res.errors[0].message);
      }
      else {
        window.location.reload();
      }
    });


    //todo 주문수 가져오기
    //todo shopNo 변경
    //todo 주문시 shopNo
    // this.orderInfo[name] = value;
  };


  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );

    return (
      <div className="header">
        <div className="top-logo">
          <h1 style={{fontSize: "23px"}}>
            <a href="/"><img src={logo} alt="onreple" /></a>
          </h1>
        </div>
        <div className="top-gnb">
          <div className="top-notice">
            <span className="notice-icon"><i data-feather="volume" className="nd-icon"></i></span>
            {
              !this.store.isUseFirstOrder ? (
                  <span className="notice-content"><Link to="/inAppOrder" className="text-red">이벤트를 놓치지 마세요!</Link></span>
              ) : this.teamUser.selShopExpiryDate && moment(this.teamUser.selShopExpiryDate) < moment() ? (
                  <span className="notice-content"><Link to="/inAppOrder" className="text-red">사용 기간이 지났습니다. 리뷰위젯 및 기능이 제한 됩니다.</Link></span>
              ) : !this.teamUser.selShopExpiryDate ? (
                  <span className="notice-content"><Link to="/inAppOrder" className="text-red">서비스 신청하기</Link></span>
              ) : null
            }

          </div>
          <Link to="/signIn" className="top-user">
            {(this.isLogin && this.emailId) ?
                <i className="material-icons">logout</i>
                :
                <i className="material-icons">login</i>
            }
          </Link>
          <div className="top-guide" title="가이드 보기">
            <a href="https://onnomad.notion.site/" target="_blank"><i
                className="material-icons">import_contacts</i></a>
          </div>
          <div className="top-guide" title="카카오 채널 상담">
            <a href="http://pf.kakao.com/_Tsxopxb/chat" target="_blank"><i
                className="material-icons">supervised_user_circle</i></a>
          </div>
          <div className="top-tool" onClick={event => {
            this.isShowSetting = !this.isShowSetting;
          }}>
            <i className="material-icons">settings</i>
          </div>

        </div>
        {this.isShowSetting ? (
            <div className="setting-box">
              <ul>
                <li className="store-info">
                  <Row>
                    <Col md="4">
                      <span className="brand-thumb"><img src={imgBrand} alt={this.store.name}/></span>
                    </Col>
                    <Col>
                      <p className="store-name">{this.store.name}</p>
                      <p className="store-id">{this.store.domain}</p>
                    </Col>
                  </Row>
                </li>
                <li className="service-info">
                  <div>
                    <FormSelect value={this.teamUser.selShopNo} name="shopNo"
                                onChange={event => {
                                  this.onChangeShop(event)
                                }}
                    >
                      {this.shops.map((item, i) => {
                        return (<option key={i} i={i} value={item.platformShopNo}>{item.shopName}</option>);
                      })}
                    </FormSelect>
                  </div>
                </li>
                <li className="service-info">
                  <div>
                    <h6>서비스 이용 현황</h6>
                    {this.store.isPaidUser && this.store.useExpiryDate ? (
                        <ul>
                          <li><span>만료일 : {moment(this.store.useExpiryDate).format('YYYY-MM-DD')}</span></li>
                        </ul>
                    ) : null}
                    <div className="text-center mt-3">
                      <Link to="/inAppOrder"><Button theme="light" size="sm">서비스 주문하기</Button></Link>
                    </div>
                  </div>
                </li>
                <li><Link to="/inAppOrderList">결제내역 및 이용내역</Link></li>
                <li><a href="https://onnomad.notion.site/" target="_blank">설명서 보기</a></li>
                <li><a href="http://pf.kakao.com/_Tsxopxb/chat" target="_blank">카카오 상담</a></li>
                <li><Link to="/reviewWriteConfig">설정</Link></li>
              </ul>
            </div>
        ) : null}

      </div>
    );
  }
}

Header.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

Header.defaultProps = {
  hideLogoText: false
};

export default Header;
