import React, { Component, useState } from 'react'
import { observable, action, computed } from 'mobx'
import axios from "axios/index";
import {reactLocalStorage as Storage} from "reactjs-localstorage";
import {Common as util} from "../utils/common";
import gql from "../utils/gql";
import { Redirect } from "react-router-dom"
// const SINGIN = gql`
// query signIn ($emailId: String!, $password: String!) {
//         signIn(emailId: $emailId, password: $password) {
//         name email token rfToken
//     }
// }`;

export class Company {

  @action updateCompany = (data, cb) => {
    let property = ['id', 'name', 'url', 'logoImg', 'tel', 'email', 'about', 'addrMain', 'addrSub']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'updateCompany',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
        if(typeof cb === 'function') {
          cb(results);
        }
        else {
          return results;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });

  };

  @action updateStore = (data, cb) => {
      //shopNo: String, cafe24UseReviewBoardNo: String,
    let property = ['id', 'shopNo', 'name', 'domain', 'logo', 'platform', 'platformId', 'addrMain', 'addrSub', 'presidentName', 'tel', 'email', 'notificationEmail', 'customerService', 'privacyOfficer', 'pTeamUser_id', 'shopNo', 'cafe24UseReviewBoardNo', 'settingProg']
    let variables = util.getDataStByKey(data, property);

    let params = {
      type: 'mutation',
      operation: 'updateStore',
      variables: variables,
      getData: 'data'
    };
    let gpqParams = gql(params);

    axios.post('/', gpqParams)
      .then(function (res) {
        let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
        if(typeof cb === 'function') {
          cb(results);
        }
        else {
          return results;
        }

      })
      .catch(function (error) {
        if(typeof cb === 'function') {
          cb(error)
        }
        return error;
      });

  };

    @action getSalesReport = (data, cb) => {

        let property = ['store_id', 'shopNo']
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'query',
            operation: 'getSalesReport',
            variables: variables,
            getData: 'data'
        };

        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                if(res.status === 200) {
                    if(typeof cb === 'function') {
                        cb(res.data.data[params.operation]);
                    }
                    else {
                        return res.data.data[params.operation];
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };


    @action applyDelStoreAccount = (data, cb) => {

        let property = ['id', 'teamUser_id', 'isRecover']
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'mutation',
            operation: 'applyDelStoreAccount',
            variables: variables,
            getData: 'data'
        };
        let gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
                if(typeof cb === 'function') {
                    cb(results);
                }
                else {
                    return results;
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };


    @action getApplySkins = (data, cb) => {

        let property = ['store_id', 'shopNo', 'actSync']
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'query',
            operation: 'getApplySkins',
            variables: variables,
            getData: 'data'
        };

        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                if(res.status === 200) {
                    if(typeof cb === 'function') {
                        cb(res.data.data[params.operation]);
                    }
                    else {
                        return res.data.data[params.operation];
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    @action getApplyShop = (data, cb) => {

        let property = ['store_id']
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'query',
            operation: 'getApplyShop',
            variables: variables,
            getData: 'data'
        };

        const gpqParams = gql(params);

        return axios.post('/', gpqParams)
            .then(function (res) {
                if(res.status === 200) {
                    if(typeof cb === 'function') {
                        cb(res.data.data[params.operation]);
                    }
                    else {
                        return res.data.data[params.operation];
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    @action applySkin = (data, cb) => {

        let property = ['store_id', 'id', 'shopNo', 'skin_no', 'script_no', 'isSetScript']
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'mutation',
            operation: 'applySkin',
            variables: variables,
            getData: 'data'
        };
        let gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
                if(typeof cb === 'function') {
                    cb(results);
                }
                else {
                    return results;
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };


    @action applyAllSkin = (data, cb) => {

        let property = ['store_id']
        let variables = util.getDataStByKey(data, property);

        let params = {
            type: 'mutation',
            operation: 'applyAllSkin',
            variables: variables,
            getData: 'data'
        };
        let gpqParams = gql(params);

        axios.post('/', gpqParams)
            .then(function (res) {
                let results = res.data.data[params.operation] !== null ? res.data.data[params.operation] : res.data;
                if(typeof cb === 'function') {
                    cb(results);
                }
                else {
                    return results;
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

}
